.calllog-container{
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    flex-grow: inherit;
    background: var(--secondary-color) !important;
}

.calllog-header{
    display: flex;
    height: 2%;
    align-items: center;
    padding: 1.5rem;
    border: 2px solid #dcd8d840;
}

.calllog-body{
    /* height: 100vh; */
    overflow-y: scroll;
}


ul{
    margin: 0;
    width: 100%;
   
    cursor: pointer;
    list-style:none;
}

.calllog-body > ul > li{
    text-decoration: none;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}
.calllogListItem-content-name{
    font-size: medium;
    font-weight: bold;
    padding-left: 1rem    
}
.calllogListItem-content, .colllog-date{
    font-size: medium;
}
.calllogListItem-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    /* width: 100%; */
    background: #ffffff;
    /* height: 100%; */
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 4.5rem;
    padding: 0 1rem;
}

.avatar-div{
    display: flex;
    padding: 1rem;
    align-items: center;
}

.list-title{
    display: flex;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: medium;
    font-weight: bold;
    text-align: center;
    color: var(--primary-color);
    align-items: center;
    padding-top: 1rem;
    position: sticky;
    top: 0;
    background: var(--secondary-color) !important;
    z-index: 1;
}

.call-log-list-item:nth-child(1){
    width:40%;
}
.call-log-list-item:nth-child(2){
    width: 25%;
}
.call-log-list-item:nth-child(3){
    width: 20%;
}
.call-log-list-item:nth-child(4){
    width: 15%;
    text-align: right;
    padding-right: 1rem;
}

.calllogListItem-content:nth-child(1){
    width:38%;
    /* padding-right: 0; */
}
.calllogListItem-content:nth-child(2){
    width:25%;

}
.calllogListItem-content:nth-child(3){
    width:20%;
    /* text-align: left */

}
.calllogListItem-content:nth-child(4){
    width:15%;
    text-align: right;
    padding-right: 1rem;
}
.calllogListItem-type{
    display: flex;
}
.calllog-icons{
    margin-right: 10px;
    color: var(--primary-color);
}
.calllog-type{
    margin-left: 15px;
}
.calllogListItemName{
    font-size: medium;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0 !important;
}

.designation {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0 !important;
}

/* .call-missed-container{
    color: red;
} */
.calllog-icon-container{
    /* height: 1.2rem; */
    width: 1.5rem;
}
.calllog-icon-container img{
    width: 100%;
    /* width: 2.2rem */
}
.incoming-call svg, .outgoing-call svg{
    width: 100%;
    fill: var(--primary);
    height: 3rem !important;
}

.miss-call svg{
    fill: var(--red-dark);
    width: 100%;
    height: 3rem !important;
}

@media only screen and (max-width: 1200px) {
    .profile-btn-div h3{
        margin-right: 0;
    }
    .header-div {
        grid-template-columns: none;
        background: white;
        box-shadow: 0px 3px 3px 0px rgb(0 0 0 / 10%) !important;
        z-index: 1;
    }
    
    .calllog-container{
        box-shadow: inset 0 4px 6px -5px rgb(0 0 0 / 16%) !important;
        /* height: 100vh; */

    }
    .calllog-body{
        /* height: unset !important; */
        overflow-y: scroll;
    }
    
    /* .calllog-mobile > li{
        padding: 1.5rem 1rem;
    } */
    .calllog-scroll-box {
        /* display: flex; */
        /* width: 100%; */
        position: relative;
        padding: 0 !important;
        align-items: center;
    }

    .calllog-mobile-list-item{
        background-color: #fff;
        display: flex;
        /* width: 100%; */
    justify-content: space-between;
    padding: 0.8rem;
    /* border-bottom: .5px solidrgba(0,0,0,.101961) !important; */
    border-bottom: 1px solid rgba(0,0,0,.101961);
    }
    /* .callLog-status-icon{
        padding-right: 1rem;

    } */
   /* .callLog-status-icon {
    border-radius: 50%;
    margin-right: 1rem;
    width: 4rem;
    height: 3.5rem;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    color: var(--primary);
    overflow: hidden;
    background-color: #faf8ff;
    border: 1px solid rgba(115,75,209,.301961);  
   } */
   .calllog-icon-container{
    /* height: 2.2rem; */
    width: 1.2rem;
  }
   .calllog-details{
    width: 70%;
    padding-left: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: flex-start;
    font-size: medium;
    font-weight: bold;
   }
   .calllog-details-name{
    color: var(--dark--grey--500);
    margin-bottom: 0 !important;
    font-size: medium;
   }
   .call-icons{
    color: var(--primary);
   }
   .calllog-type{
    margin-left: 0;
   }
   .colllog-date{
    text-align: right;
   }
   .colllog-date-day{
    color: var(--primary);
    text-transform: capitalize;
   }
   .miss-call svg, .incoming-call svg, .outgoing-call svg{
    height: 2rem !important;
   }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
    .profile-btn-div h3{
        margin-right: 0;
    }
    .header-div {
        grid-template-columns: none;
        background: white;
        box-shadow: 0px 3px 3px 0px rgb(0 0 0 / 10%) !important;
        z-index: 1;
    }
    
    .calllog-container{
        box-shadow: inset 0 4px 6px -5px rgb(0 0 0 / 16%) !important;
        /* height: 100vh; */

    }
    .calllog-body{
        /* height: unset !important; */
        overflow-y: scroll;
    }
    
    /* .calllog-mobile > li{
        padding: 1.5rem 1rem;
    } */
    .calllog-scroll-box {
        /* display: flex; */
        /* width: 100%; */
        position: relative;
        padding: 0 !important;
        align-items: center;
    }

    .calllog-mobile-list-item{
        background-color: #fff;
        display: flex;
        /* width: 100%; */
    justify-content: space-between;
    padding: 0.8rem;
    /* border-bottom: .5px solidrgba(0,0,0,.101961) !important; */
    border-bottom: 1px solid rgba(0,0,0,.101961);
    }
    /* .callLog-status-icon{
        padding-right: 1rem;

    } */
   /* .callLog-status-icon {
    border-radius: 50%;
    margin-right: 1rem;
    width: 4rem;
    height: 3.5rem;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    color: var(--primary);
    overflow: hidden;
    background-color: #faf8ff;
    border: 1px solid rgba(115,75,209,.301961);  
   } */
   .calllog-icon-container{
    /* height: 2.2rem; */
    width: 1.2rem;
  }
   .calllog-details{
    width: 70%;
    padding-left: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: flex-start;
   }
   .calllog-details-name{
    color: var(--dark--grey--500);
    margin-bottom: 0 !important;
    font-size: medium;
   }
   .call-icons{
    color: var(--primary);
   }
   .calllog-type{
    margin-left: 0;
   }
   .colllog-date{
    text-align: right;
   }
   .colllog-date-day{
    color: var(--primary);
    text-transform: capitalize;
   }
   .miss-call svg, .incoming-call svg, .outgoing-call svg{
    height: 2rem !important;
   }
}

/* @media only screen and (max-width: 320px) {
    .calllog-mobile-list-item{
        padding: 0 !important;
    }
} */