.modal--content--contact{
    font-size: small;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem !important;
}

.modal--btn{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
}
.modal--btn--left button{
    background:#3fcc35;
    border: none;
    color: #fff;
    font-size: medium;
    padding: 10px;
    margin-right: 1rem;
    border-radius: 0.2rem;

}
.modal--btn--right button{
    background-color:#dc3545;
    border: none;
    color: #fff;
    font-size: medium;
    padding: 10px;
    margin-left: 1rem;
    border-radius: .2rem;

}

.modal--btn--right button i {
    margin-left: .5rem;
}
.modal--btn--left button i {
    margin-right: .5rem;
}
.contact-popup{
    padding: 4rem;
}
.contact-popup-btn{
    margin-top: 1rem;
}
@media only screen and (max-width: 1200px){
    .MuiModal-root .contact-popup{
  width: 60% !important;
  height: 30% !important;
  padding: 4rem 1rem !important;

    }
    .modal--content--contact{
        margin-bottom: 1rem !important;
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .MuiModal-root .contact-popup{
  width: 60% !important;
  height: 25% !important;
  padding: 4rem 1rem !important;

    }
    .modal--content--contact{
        margin-bottom: 1rem !important;
    }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    .MuiModal-root .contact-popup{
        height: 35% !important;
    }
}
@media only screen and (max-width: 500px){
    .MuiModal-root .contact-popup{
        width: 90% !important;
        height: 25% !important;
        padding: 3rem .5rem !important;
      
          }
}
