
.login{
    /* Flex */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* justify-content: space-between; */
    /* UI */
    /* padding: 1rem; */
    height: 100vh;
    background-color: var(--white-color);
    /* background: transparent radial-gradient(closest-side at 50% 50%, #fff 20%, var(--primary-color) 91%, var(--primary-color) 61%, var(--primary-color) 100%) 0% 0% no-repeat padding-box; */
    background-attachment: fixed;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    /* overflow-y: auto; */
    /* position: fixed;
    top: env(safe-area-inset-top);
    bottom: env(safe-area-inset-bottom);
    left: env(safe-area-inset-left);
    right: env(safe-area-inset-right); */
}

@keyframes cursor-blink {
    0% {
      opacity: 0;
    }
}

.cursor::after {
    content: "";
    width: 2px;
    height: 25px;
    background: #000;
    display: inline-block;
    animation: cursor-blink 1.5s steps(2) infinite;
    margin-right: -2px;
}

.btn-back-wrapper {
    width: 90%;
    margin: 0 auto;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding-top: calc(env(safe-area-inset-top) + 50px); */
}

.btn-back-wrapper h1 {
    font-weight: 600;
    font-size: 2.325rem;
}

.btn-back-wrapper i{
    cursor: pointer;
    color: var(--title-text-color);
}

.btn-back-wrapper + .icon-welcome-dev{
    padding-top: 60px;
    display: block;
}
.login-top-box{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 60%;
}
.login-image{
    /* height: 50%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 20px;
}

.login-image.login-image-otp{
    /* display: flex; */
    align-items: center;
    flex-direction: column;
    /* height: 20%; */
}

.login-icon {
    max-height: 200px;
    max-width: 130px !important;
    height: auto;
    width: 40% !important;
}

.login-detail{
    gap: 0.625rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.login-image-otp + .login-detail{
    height: 80%;
    justify-content: flex-start;
}
.login-title{
    margin:0;
    color: var(--title-text-color);
    font-weight: 500;
    line-height: 3rem;
    font-size: 2.625rem;
    letter-spacing: 0px;
    font-family:'SF Pro Display', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.login-subtitle{
    color: var(--title-text-color);
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0px;
    line-height: 1.475rem;
    font-family:'SF Pro Display', sans-serif; 
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
}

.email-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* UI */
    margin: 1rem 0rem;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid var(--secondary-text-color);
}

.email-wrapper input{
    width: 100%;
    border: none;
    background: none;
    font-size: 1rem;
    line-height: 1.2rem;
    font-family:'SF Pro Display', sans-serif;
}

.email-wrapper input::placeholder{
    opacity: 0.6;
    color: var(--title-text-color) !important;
    letter-spacing: 0px;
}

.otp-wrapper{
    margin: 0.5rem 0rem 0rem 0.5rem;
    gap: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.otp-wrapper input{
    width: 3rem;
    border-radius: 0.325rem;
    padding: 0.7rem;
    line-height: 4.25rem;
    font-size: 2.625rem;
    line-height: 1.2rem;
    background: #FFFFFF;
    font-family:'SF Pro Display', sans-serif;
    margin: 0px 5px;
}
.resend-otp-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 15%;
    justify-content: space-around;
}
.btn-next{
    /* UI */
    border: none;
    padding: 1.1rem;
    color: #FFFFFF;
    border-radius: 99px;
    margin: 2rem 0rem 0rem 0rem;
    background: #734BD1 0% 0% no-repeat padding-box;
    /* Font */
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 1.175rem;
    line-height: 1.475rem;
    font-family:'SF Pro Display', sans-serif;
}

.btn-resend{
    /* UI */
    border: none;
    color: var(--title-text-color);
    background: transparent;
    margin: 0.5rem 0rem 0rem 0rem;
    /* Font */
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 1.175rem;
    line-height: 1.475rem;
    font-family:"Roboto","Helvetica","Arial",sans-serif;
}

.otp-btn {
    background-color: var(--title-text-color);
    border-radius: 4rem;
    color: #fff !important;
    opacity: 1 !important;
    font-weight: 600;
    margin-bottom: 1rem;
    padding: 1rem 3rem;
    box-shadow: 0px 0px 15px #00000026;
    white-space: nowrap;
    margin-top: 1rem;
}

.otp-field:focus {
    border: 1px solid var(--secondary-text-color);
}

.otp-wrapper .otp-field {
    width: 1rem;
    height: 1.4rem;
    text-align: center;
    border-radius: 0.325rem;
    padding: 1.2rem;
    line-height: 4.25rem;
    font-size: 2.625rem;
    line-height: 1.2rem;
    background: #FFFFFF;
    font-family:'SF Pro Display', sans-serif;
    border: 1px solid var(--secondary-text-color);
}

.error-msg{
    color: var(--red-dark);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

@media screen and (max-width:550px){
    .login-image img{
        width: 350px;
    }
    
    .login-subtitle{
        width: 80%;
    }

    .email-wrapper{
        width: 80%;
    }

    .btn-next{
        width: 80%;
    }
}

@media screen and (min-width:551px){
    /* .login-image img{
        width: 60%;
    } */
    .top-band-white-login{
        height: 70px;
    }
    .email-wrapper{
        width: 80%;
    }

    .btn-next{
        width: 80%;
    }
}

@media screen and (min-width:901px){
    .login-image img{
        width: 40%;
    }
    .login-icon + img,
    .login-image-otp img{
        width: 420px;
    }
    .email-wrapper{
        width: 75%;
    }
    .top-band-white-login{
        height: 55px;
    }

    .btn-next{
        width: 75%;
    }
    .icon-welcome-dev{
        overflow-y: auto;
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
    .login-image-otp + .login-detail{
        height: 300px !important;
        justify-content: flex-start;
    }
    .login-image.login-image-otp{
        height: 150px !important;
    }
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity :1}
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation: fadeIn 0.5s ease-in-out;
}
.top-band-white-login
{
    background-color: var(--white-color);
    height: 60px;
    text-align: left;
    color: var(--white-color);
    width: 100%;
    /* padding: 0.5rem 2rem; */
    margin-left: 1rem;
    position: fixed;
    top: 0 !important;
    z-index: 100;
}
.top-band-kki-login{
    background-color: var(--primary-color);
    height: 40px;
    text-align: left;
    color: var(--white-color);
    width: 100%;
    padding: 0.5rem 2rem;
    margin-left: 1rem;
    position: fixed;
    top: env(safe-area-inset-top) !important;
    z-index: 100;
}

.top-band-kki-login img{
    height: 35px;
    width: 190px;
}
.icon-welcome-dev{
    display: block;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    overflow-y: auto;
    /* padding-top: 20px; */
    position: absolute;
    top:  calc(env(safe-area-inset-top) + 60px);
    bottom: env(safe-area-inset-bottom);
    left: env(safe-area-inset-left);
    right: env(safe-area-inset-right);
}
  /* different techniques for iPad screening */
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    .login-image-otp img{
        width: 500px;
    }
    /* .login-top-box{
        justify-content: flex-start;
    } */
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    .icon-welcome-dev{
        overflow-y: scroll;
        max-height: 620px;
    }
    /* .login-image img{
        width: 50% ;
    } */
  }

  @media screen and (min-width: 778px) and (max-width: 1023px){
    .icon-welcome-dev{
        overflow-y: auto;
    }
    .login-image-otp + .login-detail{
        justify-content: flex-start;
        height: 400px;
    }
    .login-image > img + img,
    .login-image-otp img{
       width: 420px;
    }
  }
  @media screen and(max-width: 778px){
    .login-top-box{
        height: 50%;
    }
    .top-band-white-login{
        height: 70px;
    }
  }
.icon-welcome-dev::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
    display: none   ;
}