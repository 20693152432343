

@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
.appointment-header{
    border-bottom: 3px solid var(--primary-color);
    margin-bottom:1rem;
}
.appt-name{
    font-size: 1rem;
}
.card{
    border-bottom: 2px solid #D2C3F1;
    margin-bottom: 1rem !important;
    background: none;
    border: none;
    padding: 0px;
}
.btn-primary{
    background-color: var(--primary-color) !important;
}
.p-1{
    border: 1px solid rgba(0,0,0,.125);
    background-color: #fff;
}
.card-body{
    padding: 0
}
.col-lg-12{
    padding: 1rem;
}
.font-md {
    color:var(--primary-text-color);
    font-weight: 400;
}
.btn-primary:hover {
    border-color: #9374db;
    background: #9374db;
    color: #fff;
}
.btn-primary{
    background: #9374db;
}
.appointment-btn .accept-btn, .appointment-btn .reject-btn {
    width: 115px;
    border-radius: 15px;
}

.MuiTabPanel-root{
    overflow-y: scroll;
}

}
@media only screen and (max-width: 500px){
.card {
    border: none;
    margin-bottom: 1rem !important;
    font-size: 0.8rem;
}
.btn-primary.disabled{
    border-color: #9374db;
    background: #9374db;
    opacity: 0.3;
}
.btn-primary{
    background: #9374db !important;
    border-color: #9374db !important;
}
.col-lg-12{
    padding: 1rem;
}
.p-1{
    border: 1px solid rgba(0,0,0,.125);
}
.font-md{
    /* color: var(--secondary-color); */
    color: var(--secondary-text-color);
}
.btn-primary:hover {
    border-color: #9374db;
    background: #9374db;
    color: #fff;
}
.btn-primary{
    background: #9374db;
}
.appointment-btn .accept-btn, .appointment-btn .reject-btn {
    width: 94px;
    border-radius: 15px;
    font-size: 0.8rem;
}
.appointment-header{
    border-bottom: 3px solid var(--primary-color);
    margin-bottom:1rem;
}
.appt-name{
    font-size: 1rem;
}
}

.requestBody{
    overflow: auto;
    padding: 0;
}
.Requestcard{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* background-color: #fff; */
    background-clip: border-box;
    /* border: 1pxsolidrgba(0,0,0,.125); */
    border-radius: 0.25rem;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .p-1 {
        border: 1px solid rgba(0,0,0,.125);
    }
    .card-body .col-lg-12{
        padding:1rem;
    }
    .appointment-btn .accept-btn, .appointment-btn .reject-btn {
        width: 94px;
        border-radius: 15px;
    }
    .btn-primary {
        background: #9374db !important;
        border-color: #9374db !important;
    }
}
