.dynamic_templates-header{
    display: flex;
    /* height: 2%; */
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem 1rem 1rem 2rem;
    border: 2px solid #dcd8d840;
}
/* .Forms-header >.formcontent__header__searchbox {
    display: flex;
    width: 75% !important;
   
}
.contacts-container .row--three>.formcontent__header__searchbox{
    background-color: #fff!important;
    border-radius: 100vw;
    margin: 10px 30rem 10px 0;
} */

.dynamic-temp-title{
    display: flex;
    /* justify-content: space-between; */
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-size: medium;
    font-weight: bold;
    text-align: start !important;
    color: var(--primary-color);
    align-items: center;
    padding-top: 1rem;
    position: sticky;
    top: 0;
    background: #F5F5F5 !important;
    z-index: 1;
}


textarea {
    color: var(--dark--grey--500) !important;
}
.Dynamic_Temp_NameHeader,.DynamicTempName{
 margin-left: 1%;
 width: 20%;
 text-transform: capitalize;
}
.Dynamic_Temp_body,.DynamicTempbody{
    text-transform: capitalize;
  width: 35%;  
}
.Dynamic_Temp_Assets,.Dynamic_Temp_Status,.Dynamic_Temp_Vcard,.DynamicTempAssets,.DynamicTempStatus,.DynamicTempVcard{
   width: 15%; 
   text-transform: capitalize;
}
/* .dynamic-temp-list{
    height:calc(100% - 202px);
    overflow-y: auto;
} */
.dynamic_template_body{
    overflow-y: scroll;
    height: 90%;
    position: relative;
    /* height:calc(100% - 202px); */
}
.view i{
    margin-right: .3rem;
}

.DynamicTempStatus{
    text-transform: capitalize;
    font-size: medium;
}
@media screen and (max-width: 1200px) {
    .dynamic-temp-title {
      background-color: white !important;
    }
    .create-dynamic-floating{
        margin-right: 2rem;
        margin-bottom: 1rem;
        position: fixed;
        bottom: 3rem;
        right: 1rem;
    }
  }