.besttime-banner {
    background-color: white;
    color: var(--primary-color);    
}
.bannertext{
    justify-items: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-family: "SF Pro",sans-serif;
}
.shadowin {
    box-shadow: inset 0 0 10px #00000029;
    
}
.mainchat{

    height: 100%;
    overflow-y: auto;
    background-color: var(--secondary-color);

}
.header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0px;
    background-color: var(--secondary-color);
    
}
.headerright{
    display: flex;
    margin: 2px;
    margin-right: 16px;
    margin-left: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    

    
}

i.header{
    color: var(--primary-color);
}
i.insidefooter {
    color: var(--primary-color);
    
}
.footer{
    box-shadow: 0px 3px 6px #00000029;
    /* border:1px solid black; */
    /* margin-top: auto; */
    margin-bottom: 0px;
    padding: 20px;
    background-color: var(--secondary-color);
    
}
.msg-component-div{
    height: 100%;
        display: flex;
        flex-direction: column;
}
.insidefooter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.2rem;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    /* background-color: #734bd1; */
}
#footerinput{
    border-radius: 25px;
    width:90%;
    border:none;
    box-shadow: 0px 3px 6px #00000029;
    margin-right: 10px;
    font-size: 15px;
    padding: 0.2rem 0.7rem;
    min-height: 1.5rem;
    max-height: 30vh;
    resize: none;
    color: var(--primary-text-color);
    
}
textarea{
    overflow: auto;
}
.sendbutton{
    border-radius: 25px;
    height: 25px;
    width: 15px;
    margin-left: auto;
    margin-right:10px;
}

.avatar {
    border-radius: 50%;
    overflow: hidden;
    background-color: #FAF8FF;
    border: 1px solid #734BD14D;
    display: flex;
    align-items: center;
    justify-content: cecnter;
    color: var(--primary-color);
}


.avt-4p5 {
    width: 4.5rem;
    height: 4.5rem;
}

.avt-4p5 .initials {
    font-size: 2.3rem;
}

.avt-5 {
    width: 5rem;
    height: 5rem;
}

.avt-5 .initials {
    font-size: 2.5rem;
}

.avt-6 {
    width: 6rem;
    height: 6rem;
}

.avt-6 .initials {
    font-size: 2.8rem;
}

.avt-2 {
    width: 2rem;
    height: 2rem;
}

.avt-2 .initials {
    font-size: 16px;
}

.avt-12 {
    width: 12rem;
    height: 12rem;
}

.avt-12 .initials {
    font-size: 3.2rem;
}

.avt-16 {
    width: 16rem;
    height: 16rem;
}

.avt-16 .initials {
    font-size: 3.7rem;
}
.contact-details{
    color:var(--primary-text-color);
    
    
}
.contacttext{
    font-size: 17.6px;
    font-family: "SF Pro", sans-serif;
    margin: 0px;
}
.input-group {
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 0px;
    padding: 2rem !important;
}

.message-textarea{
        color: var(--primary-text-color);
            /* font-size: 1.6rem;
            margin-right: 0rem; */
            min-height: 5rem;
            border-radius: 20px;
            background: #fff;
            padding-left: 3rem;
            padding-right: 3rem;
            padding-top: 1.3rem;
            box-shadow: 0px 3px 6px #00000029;
            margin-right: 8rem;
}


#msg-wtsapptoggle.b-slider:before {

    background-color: #ffffff;

}
input:checked+#msg-wtsapptoggle.b-slider {

    background-color: var(--primary-color);

}

input:not(:checked)+#msg-wtsapptoggle.b-slider {

    background-color: var(--primary-color);
}
#msg-wtsappChechedId:not(:checked)+.b-slider:before {
    font-family: "Material Icons";
      content: "\e0b7";
      text-align: center;
      color: black;
      padding: 0.15rem 0.4rem;
      font-size: 1rem;

  }

#msg-wtsappChechedId:checked+.b-slider:before {
    font-family: "Font Awesome 5 Brands";
      content: "\f232";
      text-align: center;
      color: black;
      padding: 0.15rem 0.45rem;
      font-size: 1rem;

  }

  .custom-file-input::-webkit-file-upload-button {
      visibility: hidden;
  }

  .custom-file-input::before {
      /* content: 'Select some files'; */
      display: inline-block;
      /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
      /* border: 1px solid #999; */
      border-radius: 3px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      text-shadow: 1px 1px #fff;
      font-weight: 700;
      font-size: 10pt;
  }

  .custom-file-input:hover::before {
      /* border-color: black; */
  }

  .custom-file-input:active::before {
      /* background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); */
  }


  .error-footer{
    padding: 0rem 1rem;
    background: red;
    color: white !important;
    z-index: 1;
    padding: 0rem 1rem;
    background: red;
    position: relative;
    list-style-type: square !important;
    
    
  }
  .error-footer li{
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
  }

  .attached-media {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      overflow-x: hidden;
      color: var(--primary-text-color);
      /* border:1px solid black; */
  }

  .attached-media .media-tb {
      height: 30px;
      width: 130px;
      background-color: #e5e5e5;
      margin-top: 10px;
      display: flex;
      margin: 5px;
      /* border: 1px solid red; */
  }

  .attached-media .media-tb .media-left {
      flex-direction: column;
      width: 20%;
      justify-content: center;
      align-items: center;
      /* border: 1px solid green; */
      padding: 0px;
  }

  .attached-media .media-tb .media-left i {
      line-height: 15px;
      text-align: center;
      /* border: 1px solid blue; */
      margin-top: 15%;
  }

  .attached-media .media-tb .media-left p {
      font-size: 7px;
      line-height: 8px;
      /* border: 1px solid blueviolet; */
  }

  .attached-media .media-tb div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 65%;
      font-size: 10.8px;
      text-align: center;
      line-height: 28px;
      /* border: 1px solid orange; */
  }

  .attached-media .media-tb div:last-child {
      display: flex;
      width: 15%;
      justify-content: center;
      align-items: center;
      /* border: 1px solid pink; */
  }

  .grid-container {
      display: grid;
      grid-template-columns: auto auto;
      height: auto;
      max-height: 90px;
      overflow-y: scroll;
      margin-top: 10px;
      /* Track */
      /* Handle */
      /* Handle on hover */
  }

  .grid-container::-webkit-scrollbar {
      width: 3px;
  }

  .grid-container::-webkit-scrollbar-track {
      background: #f1f1f1;
  }

  .grid-container::-webkit-scrollbar-thumb {
      background: #888;
  }

  .grid-container::-webkit-scrollbar-thumb:hover {
      background: #555;
  }



  @media only screen and (max-width: 1200px) {
    .insidefooter {
        display: flex;
        padding: 0;
        background:none;
        box-shadow: none;
        border-radius: 5px;
    }

    
  }
  @media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
    .insidefooter {
        display: flex;
        padding: 0;
        background:none;
        box-shadow: none;
        border-radius: 5px;
    }

    
  }

  /* image display popup css starts */
.imagePopup{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000070;


    /* border: 1px solid red; */
    color: black;
}
.outerdiv{
    position: relative;
    /* border: 1px solid greenyellow; */
    
}
.imageBox{
    /* border: 1px solid blue; */
    
}
.imageBox img{
    /* border: 1px solid orangered; */
    display: block;
    max-width: 100vh;
    /* max-height: 100vh; */
}
.imageBox #downloadBtn{
    /* border: 1px solid blue; */
    position: absolute;
    right: 5px;
    bottom: 5px;
}
.imageBox #closeBtn{
    /* border: 1px solid blue; */
    position: absolute;
    right: 5px;
    top: 5px;
}
.popupBtn{
    /* border: 1px solid orangered; */
    border-radius: 50%;
    text-decoration: none;
    font-size: 15px;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    color: white !important;
    background-color: var(--primary);
    display: flex;
    cursor: pointer;
}

/* image display popup css ends */

.optOut{
    text-align: center;
}

.img-size{
    max-width: 800px;
    max-height: 400px;
}