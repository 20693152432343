.responsive-dialog-title {
    display: flex !important;
}

.custom-modal {
    position: fixed;
}

.new_chat_modal input {
    height: 1rem;
    overflow: hidden;
}

.new_chat_modal {
    padding: 3rem 2rem 2rem 2rem;
}


/* .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(0,0,0,.160784);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.Modal .modal-body{
    padding: 1rem 2rem 2rem 2rem;
}

@media only screen and (min-width: 1200px)
{
    .form-group--first {
    margin-bottom: 4rem;
}
.modal .modal-content {
    width: 30%;
    padding: 3.5rem;
    border-radius: 2rem;
    border: none;
    height: unset;
}
}
.modal .modal-content {
    width: 30%;
    padding: 3.5rem;
    border-radius: 2rem;
    border: none;
    height: unset;
}
.form-group--first p {
    color: #734bd1;
    font-size: 1.5rem;
    font-weight: 700;
}

.form-group--first div {
    display: inline-flex;
    margin-right: 1.6rem;
    justify-content: center;
    align-items: center;
    padding-left: 0;
}

.custom-control-input:disabled~.custom-control-label {
    color: #595959;
}
.form-group--first div label {
    color: #595959;
    font-weight: 500;
    padding-left: 0.4rem;
}

.form-group--fourth input, .form-group--third input {
    width: 100%;
    border: none;
    box-shadow: 0 0 3px -1px;
   
    height: 2.5rem;
    border-radius: 3rem;
    color: #595959;
    font-weight: 500;
    -webkit-appearance: none;
    background-color: #faf8ff!important;
    outline: none;
}


.form-group--fifth .floating-label, .form-group--fourth .floating-label, .form-group--third .floating-label {
    position: absolute;
    top: -1rem;
    left: 2rem;
    color: #734bd1;
    font-size: 1.1rem;
    font-weight: 700;
    background: #fff;
    padding: 0 0.4rem;
}


.modal {
    display: none;
    position: fixed;
    padding-top: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
    background-color: rgba(0,0,0,.4);
} */

.modal-header {
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 0rem 1rem;
    border-bottom: 1px solid #00000029;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.d-flex {
    display: flex !important;
}

.font-newchat {
    font-size: 1.2rem !important;
    line-height: 2.8rem;
    font-weight: bold;
}

.modal-header h1 {
    color: var(--secondary-text-color);
}

.modal-header .close {
    float: right;
    font-size: 28px;
    font-weight: bold;
    border-radius: 0 0 0 90px;
    opacity: 1;
    text-align: right;
    background-color: transparent !important;
    color: var(--secondary-text-color);
    width: 6.5rem;
    cursor: pointer;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
}

.modal-body .selecttype-radio-btns {
    position: relative;
    margin-bottom: 1.2rem;
}

.modal-body .selecttype-radio-btns .selectfont {
    font-size: 2rem !important;
    line-height: 2.5rem;
    color: var(--primary-color) !important;
    font-weight: 700;
}

#demo-customized-radios {
    font-weight: bold;
    color: var(--primary-color);
}

.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 100vw !important;
}

p:not(:last-child) {
    margin-bottom: 10px;
}

.inputRounded {
    border-radius: 100vw !important;
}

.form-fields-div {
    display: flex;
    flex-direction: column;
    align-items: normal;
    row-gap: 1rem;
}

.form-fields-div .create-btn {
    border: 1px solid transparent;
    border-radius: 4rem;
    text-decoration: none;
    background-color: rgb(115, 75, 209);
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    position: relative;
    transition: all ease-in-out 0.25s;
    color: #fff;
    font-weight: 600;
    margin-bottom: 2rem;
    padding: 0.5rem 1.2rem;
    box-shadow: 0px 0px 15px #00000026;
    white-space: nowrap;
    margin: 0 auto;
}

.font-md {
    color: var(--primary-color);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
}

.asso-prescriber-div {
    display: flex;
    flex-direction: column;
}

.css-19285mc-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--primary-color) !important;
}

.css-19285mc-MuiFormLabel-root-MuiInputLabel-root {
    font-weight: bold !important;
}

.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #dee2e6a8 !important;
    border-width: 1px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #dee2e6a8 !important;
    border-width: 1px !important;
}

.presListDiv {
    position: absolute;
    height: 5rem;
    overflow-y: scroll;
    width: 90% !important;
    background: white;
    z-index: 3;
    font-size: 20px;
}

.asso-prescriber-div>div>div {
    width: 100%;
}

#presList div {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    padding: 0.5rem;
    font-weight: 00;
    color: #929292;
}

.template_body_form {
    margin-top: 1rem;
}

.template_body_form p {
    font-size: small;
}

.template_body_form textarea {
    width: 96%;
    max-height: 30vh;
    height: 110px;
    opacity: 1;
    display: block;
    border: 1px solid;
    padding: 10px;
    text-align: inherit;
    font-size: small;
    color: #495057 !important;
}

.template_assets_form {
    margin-top: 1.3rem;
    position: relative;
    border: 1px solid #dee2e6a8 !important;
}

.template_assets_form ul {
    list-style: none;
    font-size: medium;
    /* margin: 0 1rem; */
    padding: 1rem;
}

.template_assets_form li {
    position: relative;
    /* padding: 1rem; */
    /* border-color: #dee2e6a8 !important; */
}

.template_assets_form li::before {
    content: "";
    position: absolute;
    top: .5rem;
    left: 0;
    height: 0.7rem;
    width: 0.7rem;
    background: rgba(0, 0, 0, .160784);
    border-radius: 100%;
}

.template_assets_form li a {
    text-decoration: none;
    color: #929292;
    margin-left: 1.2rem;
}

.template_assets_form li a:hover {
    color: var(--primary-color);
}

.template_assets_form label {
    font-size: small;
    position: absolute;
    top: -1rem;
    left: 0rem;
    color: var(--primary-color);
    /* font-size: 1.1rem; */
    font-weight: 700;
    background: #fff;
    padding: 0 0.4rem;
}

.newChatModalBody {
    display: flex;
    justify-content: space-between;
}

.width100per {
    width: 100%;
}

.inputDisbale {
    background: var(--bg--purple);
    color: var(--primary-text-color)!important;
    border-radius: 100vw !important;
}

.inputEnable {
    background: var(--white);
    color: var(--dark--grey);
    border-radius: 100vw !important;
}

.inputEnable input:focus {
    border-radius: 100vw !important;
}


/* .new-chat-prescriber .createContactAssociate >h1{
 display: none;
} */

.new_chat_modal .MuiBox-root {
    width: 30%;
    position: relative;
    padding: 1.5rem;
}

.new_chat_body {
    height: 450px;
    max-height: 50%;
    overflow-y: auto;
    overflow-x: hidden;
}

.newchat-floating-form {
    margin-top: 1rem;
    /* display: none; */
}

.newchat-floating-form textarea {
    width: 96%;
    max-height: 30vh;
    height: 110px;
    border: none;
    background-color: rgba(0, 0, 0, .160784);
    opacity: 1;
    padding: 10px;
    text-align: inherit;
    font-size: small;
    color: #495057 !important;
}

.newchat-floating-form p {
    font-size: small;
    margin-top: 0 !important;
    display: flex;
    justify-content: flex-end;
}

.NewChatModalOthersName div {
    width: 100%;
}

.othesNameDiv {
    position: absolute;
    background: white;
    z-index: 2;
    font-size: 1rem;
}

.radiobackcol {
    background-color: var(--primary-color);
}

.optedOutModalText {
    color: red;
    font-size: 1rem;
}

.checkBoxField {
    margin-bottom: 1.5rem !important;
    margin-left: 1rem;
}

.fieldInput {
    font-size: medium;
    margin-left: 0px !important;
}

.checkboxLabel {
    margin-left: 1rem;
    font-size: medium;
    vertical-align: middle;
}