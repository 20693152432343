.mobileChatPage{
    display: flex;
    flex-direction: column;
    position: relative;
    /* width: 100%; */
    height: 100%;
    position: relative;
    /* z-index: 103; */
    /* top: 0; */
    /* left: 0;
    right: 0; */
    background-color: #faf8ff;
    /* display: flex; 
    flex-direction: column;
    position: absolute;
    width: 90%;
    height: 96%;
    z-index: 2;
    top: 20px;
    background-color: #faf8ff; */
}
.header-wrapper{
    position: relative;
    /* top:0; */
    z-index: 1;
    width: inherit;
}
.error-footer-mobile{
   position: relative;
   top: -1.5rem;
}
.mainchat{
    -webkit-overflow-scrolling: touch;
    overflow: auto;  
}
.mobile-mainchat{
    position: relative;
    /* margin-top: 4rem; */
    /* padding-top: 73px; */
    /* top: 73px; */
    /* bottom: 80px; */
}

.mobileChatPage .besttime-banner {
    background-color:var(--primary-color);
    color: white;

}

.mobileChatPage .sender .msg {
    margin-left: auto;
    background: #ffffff;
}

.mobileChatPage h5 {
    font-size: 1rem;
}

.mobileChatPage .msgtext {
    
    width: 70%;
}



.mobileChatPage .header{
    padding: 0 0.5rem;
}
.mobileChatPage .header i{
    color: var(--primary-color);
}

.mobileChatPage .received .msg {
    margin-right: auto;
    background: var(--primary-color);
    color: white;
}

.mobileChatPage .footer{
    box-shadow: 0 4px 30px 5px rgb(0 0 0 / 16%);
    background-repeat: no-repeat;
    background-size: 108%;
    background-position: 50%;
    background-color: var(--primary-color);
    position: absolute;
    bottom: 0;
    /* min-width: 100%; */
    /* display: flex;
    justify-content: center;
    flex: 1;
    width: 100%; */
    width: inherit;
}
.mobileChatPage .footer i{
color: white;
}
.mobile-footer{
    padding: 20px 0px !important;
}

.mobileChatPage .mobileFooterSend button{
   
    top: -100%;
    
}


 /* image display popup css starts */
 .mobileimagePopup{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000070;


    /* border: 1px solid red; */
    color: black;
}
.mobileouterdiv{
    position: relative;
    /* border: 1px solid greenyellow; */
    padding-top:8px;
    padding-bottom:8px;
    
}
/* .mobileimageBox{
    border: 1px solid blue;
    
} */
.mobileimageBox img{
    /* border: 1px solid orangered; */
    display: block;
    max-width: 100%;
    max-height: 100%;
}
.mobileimageBox #mobiledownloadBtn{
    /* border: 1px solid red; */
    position: absolute;
    right: 5px;
    bottom: 10px;
}
.mobileimageBox #mobilecloseBtn{
    /* border: 1px solid red; */
    position: absolute;
    right: 5px;
    top: 10px;
}
.mobilepopupBtn{
    /* border: 1px solid orangered; */
    border-radius: 50%;
    text-decoration: none;
    font-size: 15px;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    color: white !important;
    background-color: #734BD1;
    display: flex;
    cursor: pointer;
}

#scrollableDiv{
    display: flex;
    /* flex: 1; */
    display: -webkit-flex;
    flex-direction: column;
    transform: translate3d(0, 0, 0);
-webkit-transform: translate3d(0, 0, 0);
perspective: 1000;
-webkit-perspective: 1000;
/* opacity: 1 !important; */
scroll-behavior: auto;

animation: redraw 1s linear infinite;
}
/* #headerVal{
    display: none ;
}
#MobHeader{
    display: none;
}
.chatListDiv{
    display: none !important;
}
.msg-component-div{
    display: block ;
} */

/* image display popup css ends */


@keyframes redraw{
    0% {opacity: 1;}
    100% {opacity: .99;}
}
.mobileChatPage{
    position: relative;
}



@media only screen and (min-width: 768px ) and (max-width: 1200px){
    .mobileimageBox img{
        /* border: 1px solid blue; */
        
        max-height:900px;
        /* height:calc(100vh-500px); */
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .mobileimageBox img{
        /* border: 1px solid blue; */
        
        max-height:900px;
        /* height:calc(100vh-500px); */
    }
}
@media only screen and (max-width: 767px ){
    .mobileimageBox img{
        /* border: 1px solid blue; */
        max-height:610px;
        /* height:calc(100vh-500px); */
    }
    #main .MuiSnackbar-root{  
        bottom: calc( env(safe-area-inset-bottom) + 8px) ;
    }
}