.name-avatar-div{
    display: flex;
    flex-direction: column;
}

.contact-avatar-div,
.name-avatar-div h1{
    display: flex;
  justify-content: center;
}

.contact-avatar-div{
    padding: 0 1rem 1rem 1rem;
}

button:disabled {
    opacity: 0.7;  
}

.name-avatar-div .buttons-div{
    display: flex;
    width: 60%;
    justify-content: space-evenly;
    margin: auto;
}
.contactDetail {
    opacity: 1 !important;
    visibility: visible !important;
}
.name-avatar-div .buttons-div button{
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.contacts-detials-info{
    display: flex;
    flex-direction: column;
}

.name-contact-details-div hr{
    height: 4px;
    opacity: 1.25;
    color: var(--primary-color);
}
.name-contact-details-div h3{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: -13px;
}

.font-md-caps{
    font-size: medium;
    font-weight: 300;
}
.font-md-title{
    display: flex;
    justify-content: center;
        font-size: medium;
        font-weight: bold;
}

.font-md-normal{
    font-size: 1rem;
    font-weight: 300;

}

.margin-top{
    margin-top: 2rem;
    margin-bottom: -1rem;
}

.contactDetailsButton > button >svg {
    color: white !important ;
}

@media only screen and (min-width: 768px) and (max-width: 1200px){
    #modalOuter{
        margin-top:var(--marginTop);
        height:100%!important;
    }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    #modalOuter{
        margin-top:var(--marginTop);
        height:100%!important;
    }
}