.room{
    /* height: 35rem; */
    /* height: 100%; */
    display: flex;
    margin: 0.5rem;
}
.sectionVideo{
    display: flex;
    /* min-width: 75%; */
    width: 100%;

    
    background: white;
    border: 1px solid lightgray;
    justify-content: space-between;
    /* padding: 1rem; */
    flex-direction: column;
}
.sectionVideoMain,.videochatContainer{
    height: 100%;
}
.sectionVideoActions{
    /* height: 15%; */
}
.actionsDiv{
    /* margin: 1rem; */
    border-top: 1px solid lightgray;
    padding: 1rem;
    display: flex;
    /* width: 100%; */
    justify-content: space-evenly;
}
.videochatfooter{
     /* margin: 1rem; */
     border-top: 1px solid lightgray;
     padding: 1rem;
     display: flex;
     /* width: 100%; */
     justify-content: space-evenly;
}
.sectionChat{
    
    background:white;
    
    width: 30%;
    border: 1px solid lightgray;
}



.participantsDiv{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    flex: 1;
    height: 100%;
    /* overflow: auto; */
    
}
/* .participantsDiv > div{
    position: relative;
    vertical-align: middle;
    align-self: center;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    box-shadow: var(--shadow-dark);
    background: #fff;
    animation: show 0.4s ease;
    
   
}

.participantsDiv >div video{
    position: absolute;
    right: 0;
    object-fit: cover;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 10px;
    overflow: hidden;
    left: 0;
    top: 0;
    background-size: cover;
    overflow: hidden;
    -webkit-transition: margin-top 1s ease-in-out;
    -moz-transition: margin-top 1s ease-in-out;
    -o-transition: margin-top 1s ease-in-out;
    transition: margin-top 1s ease-in-out;
} */

.sectionChatMain{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.videochatfooter{
   background-color: var(--primary-color);
}
.videochatfooter >input{
    font-size: 1rem;
    border-radius: 5px;
    border: none;
   
}

.webviewer{
    width: 100%;
    height: 100%;
}

.videomessage{
    padding: 0.5rem;
}


.videochatContainer ul{
        padding: 0.5rem;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 29rem;
        padding-top: 2.5rem;
        overflow-y: scroll;
}
.videochatContainer{

    overflow: auto;
}

.videochatContainer li{
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 4px;
    box-shadow: 1px 2px 2px 0px rgb(0 0 0 / 20%);
    border: 0.3px solid rgba(0,0,0,0.5);
	position: relative;
	margin-bottom: 0.5rem;
}


.videochatContainer .out {
    align-self: flex-end;
}


.out div{
    text-align: end;
}


/* for Mobile */
@media only screen and (max-width: 600px) {
  
    /* .sectionVideoMain{
        overflow: auto;
    } */
    .room{
          margin: 0 !important;
    }
    .sectionChat{
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        top: 0;
        z-index: 99;
        left:0
    }
    
  }




  .roomNew{
  background: yellow;
    width: 100%;
}

.videoBackbtn{
    
        border: none;
        background-color: transparent;
        color: #734bd1;
        font-size: 1rem;
        top: .3rem;
    
}


.userIconvideoChat {
    width: 1.5rem;
    height: 1.5rem;
}
.userIconvideoChat img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.chatListDivVideo{
    padding: 0.5rem;
    align-items: baseline;
    display: flex;
    width:93%
}

.videoBackBtnclose{
    color: #734bd1;
    /* float: right; */
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;

}

.popoverVideo{
    padding: 0.5rem;
    font-size: 1rem;
}
.popoverVideo div:not(:last-child){
    border-bottom: 0.5px solid gray;
}