/* .col-xl-3  .content__header__searchbox {
    width: 100%;
} */

input{
    box-sizing:border-box
}
.row--three>.content__header__searchbox input:focus{
    border:0;
    outline:none;
    box-shadow:none;
}
.badge-container{
    margin-top: -4px;
    text-align: center;
}
.badge{
    font-size: small;
    border-radius: 100% !important;
    padding: 0.45rem !important;
    background-color: var(--tertiary-color) !important;
    color: white;
    line-height: .5 !important;
    visibility: hidden;
    visibility: visible;
    /* margin-top: -6px; */
}
/* .searchHeader > #basic-addon1, .searchHeader > #basic-addon3 {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}  */

#scrollableDiv{
    /* height:calc((100vh - 235px));  */
    /* width:attr(data-header); */
}
@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine)
{

.row--three>.content__header__searchbox {
    display: flex;
    padding: 0px !;
    margin-bottom: 0.3rem;
}
#scrollableDiv{
    /* height: calc(100vh - 3rem); */
}
} 

.searchHeader{
box-shadow: 0 4px 15px rgb(0 0 0 / 16%);
padding: 0.5rem;
background-color: var(--secondary-color);
}


.row--three>.content__header__searchbox .search {
    border: none;
}
/* .row--three>.content__header__searchbox {
    padding: 0 1rem 0 1rem;
   
    margin-bottom: 1rem;
} */
.row--three>.content__header__searchbox  input {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none;
}

.row--three #basic-addon1, .row--three #basic-addon3 {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

}
@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
    /* .row--three >.content__header__searchbox {
        display: flex;*/
       
        /* margin-bottom: 0.3rem; */
    
 /*   }*/
    .row--three #basic-addon1, .row--three #basic-addon3 {
        background-color: #fff!important;
        border: none;
    }
}


.chatListPage{
    align-items: center;
    width: unset !important;
    margin: 0.3rem;
    display: flex;
    justify-content: space-around;
}

.chatListPage .input-group-text{
    line-height: 0 !important;
    padding: 0% !important;
}
.chatListPage .input-group-prepend{
    display: flex;
    height: 2.75rem;
}

.chatListPage .create_newchat {
    background: var(--primary-color);
    border-radius: 100vw;
    color: #fff;
    width: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.2rem;
    font-size: 18px;
}
.medical-icon::before{
    font-size: xx-large;
}


.chatListItem-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    /* width: 100%; */
    background: #ffffff;
    /* height: 100%; */
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 4rem;
    margin: 1rem;
    padding: 0 1rem 0 0;
}

/* .chatList-div{
    display: flex;
    width: 100%;
    position: relative;
} */
.chatListItem-div .avatar-div{
    display: flex;
    padding: 0 .6rem 0 1rem !important;
    align-items: center;
   
}

.chatListDetails{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0.5rem;
    padding-left: 0px;
    /* flex: 1; */
}

.chatListDetails >div{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
}
.chatListDetails >h4, .chatListDate >h4 { 
    margin-bottom: 0;
}
.chatListDetailsName{
    font-size: medium;
    font-weight: bold;
}
.ChatListMsg{
    font-size: 1rem;
}

.chatListDate{
    display: flex;
    /* align-content: stretch; */
    /* flex-wrap: wrap; */
    /* justify-content: space-evenly; */
    flex-direction: column;
}
.msgDate{
    margin-top: 0.3rem;
    font-size: small;
}
/* .chatListDate >p{
    margin-bottom:0;
} */
.chatListDetails{
    width: 75%;
}
.chatListName{
    /* width: 18%; */
}
/* .chatListDate{
    width: 10%;
} */

.content__header__searchbox{
    display: flex;
}

.chatListDetails h4{
    justify-content: flex-start;
    color: var(--primary-text-color);
}

/* for Mobile */
@media only screen and (max-width: 1200px) {
    .header-div {
        grid-template-columns: none;
        background: white;
        box-shadow:none !important;
        /* z-index: 1; */
    }
    /* #headerVal{
        display: block ;
    }
    #MobHeader{
        display: block;
    }
    .chatListDiv{
        display: block !important;
    }
    .msg-component-div{
        display: none !important;
    } */
    .chatListItem-div{
        margin: 0;
        box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #00000024;
    }
    .chatListDiv{
        width: 100%;
    }
    .chatListDiv .search-chats{
        background-color: #f4f0ff!important;
    }
    .chatListDiv .floating{
        position: fixed;
        bottom: 3rem;
        right: 1rem
    }
    .chatListPage .content__header__searchbox{
        width: 95%;
    }
    .floating-button{
        background-color: var(--primary-color);
        color: #ffffff;
        border: none;
        border-radius: 100vw;
        width: 1.3rem;
        height: 1.5rem;
        cursor: pointer;
        box-shadow: 0px 2px 8px #00000052;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        padding: 1rem;
    }

    .searchHeader{
        padding: 0.5rem;
        background-color:#ffffff;
        border: 2px solid #dcd8d840;
    }
    
  }
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
    .header-div {
        grid-template-columns: none;
        background: white;
        box-shadow:none !important;
        /* z-index: 1; */
    }
    /* #headerVal{
        display: block ;
    }
    #MobHeader{
        display: block;
    }
    .chatListDiv{
        display: block !important;
    }
    .msg-component-div{
        display: none !important;
    } */
    .chatListItem-div{
        margin: 0;
        box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #00000024;
    }
    .chatListDiv{
        width: 100%;
    }
    .chatListDiv .search-chats{
        background-color: #f4f0ff!important;
    }
    .chatListDiv .floating{
        position: fixed;
        bottom: 3rem;
        right: 1rem
    }
    .chatListPage .content__header__searchbox{
        width: 95%;
    }
    .floating-button{
        background-color: var(--primary-color);
        color: #ffffff;
        border: none;
        border-radius: 100vw;
        width: 1.3rem;
        height: 1.5rem;
        cursor: pointer;
        box-shadow: 0px 2px 8px #00000052;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        padding: 1rem;
    }

    .searchHeader{
        padding: 0.5rem;
        background-color:#ffffff;
        border: 2px solid #dcd8d840;
    }
    
  }
  .chatlistbackground{
    background: var(--secondary-color) !important;
  }

  .chatlistbackground .infinite-scroll-component__outerdiv .infinite-scroll-component {
    overflow-x: hidden !important;
  }