.media-main-div{
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #F7F7F7;
}

.media-sidebar-div{
    width: 22%;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px #00000029;
}

.media-docviewer-div{
    flex-grow: 1;
}

.media-sidebar-innerDiv{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 1.8fr;
    height: 100%;
}

.searchbar-div{
   grid-row: 1;
   height: 4rem;
}

.searchbar-input-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: none;
    width: 100%;
}


.docs-search-input{
    border: 1px solid #E1E7FF;
    border-radius: 100vw;
    color: #828287;
    width: 90%;
    font-size: .9rem;
    background-color: #F6F8FF;
    padding: 0 1rem;
    height: 60%;
}


.tabs-main-div{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 1.8fr;
}
.tabs-heading-div{
    grid-row: 1;
    background: #F0ECFA;
}
.tabs-inner-div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    font-size: 1.2rem;
}

.doctab{
    width: 33.33%;
   cursor: pointer;
    padding: 0.8rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    font-weight: 500;

}

.activedoctab{
    border-bottom: 4px solid #F6780D;
    background: transparent !important;
}

.doc-list-div{
    padding: 5px 1rem 5px 1rem;
}

.listitem-div{
    /* height: 3rem; */
    border: 0px solid;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    
}
.withBg{
    background-color: #E2DAF5;
}

.doc-list-div .docs-ul{
    overflow-y: scroll;
    height: 74%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.doc-list-div .docs-ul::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.doc-list-div .docs-ul {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.docs-li{
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    width: 100%;
}
/* .file-options{
    display: none;
} */
.file-options{
    display: flex;
    margin-right: 1rem;
    border: none;
    background: transparent;
}
.options-div{
    position: absolute;
    /* width: rem; */
    padding-right: 0.5rem;
    /* height: 4rem; */
    display: block;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    background: #FFFFFF;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    right: 7px;
    z-index: 999;
    margin-right: 1rem;
    font-size: 0.8rem;
}

.media-page-heading{
    display: none;
}
.search-btn{
    display: none;
}


/* for Mobile */
@media only screen and (max-width: 1200px) { 

    .media-main-div{
        height: 100vh;
    }

    .media-sidebar-div{
        width: 100%;
        box-shadow:none;
    }
    .searchbar-div{
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #595959;
        padding: 0 1rem;
    }

    .search-btn{
        display: block;
        background: transparent;
        border: none;
        font-size: smaller;
    }

    .searchicon{
         color: #828287;;
    }

    .media-page-heading{
        font-size: 2rem;
        font-weight: 700;
        display: block;
    }

    .tabs-heading-div{
        background:#FFFFFF;
    }
    .tabs-inner-div{
        height: auto;
    }

    .activedoctab {
        color: #734bd1 !important;
        border-bottom: 4px solid #734bd1;
        background: transparent !important;
        font-weight: bold;
    }

    .doc-list-div{
        padding: 0;
    }

    .listitem-div{
        height: 5rem;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 0;
    }

    /* .searchbar-input-div{
        display: none;
    } */

    .media-docviewer-div{
        /* display: none; */
    }
    

    .tabs-main-div{
        grid-template-rows: 0fr 1.8fr;
    }

    .doc-list-div .docs-ul{
        height: 85%;
    }


    

   
    .circle-svg{
        width: 1rem;
        height: 1rem;
    }
    
    .docviwer-mob-div{
        position: absolute;
        /* height: 100%; */
        /* width: 100%; */
        /* top: 20px;
        left: 20px;
        right: 20px; */
        background-color: white;
        display: block;
        z-index: 9;
    }

    
    .webviewerMobile{
        height: 85%;
    }
    .docviwer-flex-div{
        display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 0;
    }
    .measurement-container,.DocumentContainer{
        background-color: white !important;
    }

}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) { 

    .media-main-div{
        height: 100vh;
    }

    .media-sidebar-div{
        width: 100%;
        box-shadow:none;
    }
    .searchbar-div{
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #595959;
        padding: 0 1rem;
    }

    .search-btn{
        display: block;
        background: transparent;
        border: none;
        font-size: smaller;
    }

    .searchicon{
         color: #828287;;
    }

    .media-page-heading{
        font-size: 2rem;
        font-weight: 700;
        display: block;
    }

    .tabs-heading-div{
        background:#FFFFFF;
    }
    .tabs-inner-div{
        height: auto;
    }

    .activedoctab {
        color: #734bd1 !important;
        border-bottom: 4px solid #734bd1;
        background: transparent !important;
        font-weight: bold;
    }

    .doc-list-div{
        padding: 0;
    }

    .listitem-div{
        height: 5rem;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 0;
    }

    /* .searchbar-input-div{
        display: none;
    } */

    .media-docviewer-div{
        /* display: none; */
    }
    

    .tabs-main-div{
        grid-template-rows: 0fr 1.8fr;
    }

    .doc-list-div .docs-ul{
        height: 85%;
    }


    

   
    .circle-svg{
        width: 1rem;
        height: 1rem;
    }
    
    .docviwer-mob-div{
        position: absolute;
        /* height: 100%; */
        /* width: 100%; */
        /* top: 20px;
        left: 20px;
        right: 20px; */
        background-color: white;
        display: block;
        z-index: 9;
    }

    
    .webviewerMobile{
        height: 85%;
    }
    .docviwer-flex-div{
        display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 0;
    }
    .measurement-container,.DocumentContainer{
        background-color: white !important;
    }

}

.presentButton{
    font-size: 0.8rem;
    background:#734BD1;
    padding: 0.3rem;
    border-radius: 5px;
    color: white;
    margin-right: 1rem;
}



.folderContainer{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.folderitem {
    /* align-items: center; */
    margin: 1rem;
    width:4rem;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    background: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: clip;
}
.folderName{
   font-size: 1rem;
}

.docviwer-mob-backBtn{
    border: none;
    margin-left: 1rem;
    background-color: transparent;
    color: #734bd1;
    font-size: 1rem;
    top: .3rem;
}

.folderName{
    word-wrap: break-word;
    width: 100%;
}