.home-container {
  display: grid;
  grid-template-columns: 8% 92%;
  grid-template-rows: 100vh;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.main-content-div {
  display: grid;
  grid-template-rows: 10% 90%;
}
@media only screen and (max-width: 1024px) {
  .footerMob {
    display: flex !important;
    justify-content: center;
  }
  .main-content-div {
    grid-template-columns: 100vw;
  }
}
@media only screen and (min-width: 1024px) {
  .footerMob {
    display: none;
  }
}
/* for mobile */
@media only screen and (max-width: 1200px) {
  .home-container {
    display: block;
  }

  .notMobile {
    display: none !important;
  }
  .onlyMobile {
    display: block;
  }

  .main-content-div {
    grid-template-rows: auto;
  }
  #main {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
  .home-container {
    display: block;
  }

  .notMobile {
    display: none !important;
  }
  .onlyMobile {
    display: block;
  }

  .main-content-div {
    grid-template-rows: auto;
  }
  #main {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.home-footer {
  /* position: fixed !important; */
  /* bottom: 0; */
  bottom: env(safe-area-inset-bottom);
  width: 100%;
}
