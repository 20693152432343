
@media only screen and (max-width: 1200px){

.mobile-profile-sidebar-container {
    display: grid;
    position: absolute;
    background-color: #ffffff;
    /* right: 0; */
    left: 0;
    top: 0%;
    /* height: calc(100vh / 1); */
    height: 100% !important;
    z-index: 999;
    width: 100% !important;
    /* padding: 1rem; */
    box-shadow: 0px 4px 15px #00000029;
    transition: all .25s ease-in-out;
    /* animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards; */
}
.profile-detials-left{
  /* border: 1px solid black; */
   /* height: 2.5rem; */
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   font-size: .8rem;
}

/* {
    color: white;
} */
.profile-header-left{
    padding: 0 1rem;
    background-color: var(--primary);
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: 50%;
    height: 50%;
    /* position: fixed; */
    /* width: 100%; */
    z-index: 5;
}
.profile-header-right{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 2rem;
    
  }
  .mobile-sidebar-avatar{
    margin-left: 1rem !important;
  }
  .profile-header-right > h1{
    font-size: 1.2rem !important;
    font-weight: bold !important;
  }
  
  .profile-header-left span{
    cursor: pointer;
    font-size: 1.2rem;
    width: 2rem;
    text-align: center;
    font-weight: bolder;
    color: white;
    /* border-radius: 50%; */
    /* background: #FAF8FF; */
    height: 100%;
    outline: none;
    border: none;
  }
  .mobile-profile-footer{
    height: unset !important;
  }
  





  .singlerow{
    align-items: center;
    width: 100%;
    /* justify-content: center;
    display: flex; */
    /* border: 1px solid blue; */
    /* margin-left: 10%;
    margin-right: 10%; */
    width: 145px;
  }
  .settrow{
    display: flex;
  }
  .dropdowns{
    /* border:1px solid green !important; */
    /* box-shadow: 1px 1px 10px #888888,1px 1px 10px #888888,1px 1px 10px #888888; */
    border-radius: 0px  !important;
  
    /* min-width: 10px;  */
    max-width: 120px;
    padding: 2px ;
  }
  .dropdowns2{
    /* border:1px solid green; */
    border-radius: 0px 15px 15px 0px  !important;
    /* box-shadow: 1px 1px 10px #888888; */
  
    /* min-width: 10px;  */
    max-width: 50px;
    padding: 2px ;
  }
  .dropdowns3{
    /* border:1px solid green; */
    border-radius: 0px 15px 15px 0px  !important;
    /* box-shadow: 1px 1px 10px #888888; */
  
    /* min-width: 10px;  */
    width: 75px;
    padding: 2px ;
  }
  .dropdownsicon{
    /* border:1px solid green; */
    border-radius:  15px 0px 0px 15px;
    width: 20px;
    height: fit-content;
    padding-left: 2px;
  }
  .dropdownCalltype > i{
    color: #734BD1;
  }
  icon svg, .dropdownsicon svg{
    fill: var(--primary)  !important;
  }
  .unselect_icon svg{
    color: #d4d4eb;
    /* background-color: #FAF8FF; */
    height: 1.2rem;
  }
  .notification_icon_box{
    margin-left: 5px;
    display: flex;
    /* justify-content: space-around; */
  }
  .unselect_icon, .select_icon{
  
    /* border: solid 1px var(--primary-text-color); */
   /* border-radius: 100vh; */
  }
  .select_icon svg{
    /* background-color: #FAF8FF; */
    fill: var(--primary-text-color);
    height: 1.2rem;
  }
  align-notification-iocn{
    margin-top: 5px;
      margin-bottom: 9px;
      /* display: flex; */
      /* flex-direction: column; */
      /* justify-content: space-around; */
      /* align-items: center; */
      font-size: 15px;
      /* margin-left: auto;
      margin-right: auto; */
  }
  .notification_icon_box{
    max-width: 120px;
    width: 20rem;
  }
  .editicon{
    cursor: pointer;  
    font-size: 17px;
    align-items: center;
  }

  .editicon > svg {
    filter: invert(35%) sepia(70%) saturate(2212%) hue-rotate(3deg) brightness(95%) contrast(97%);
  }
  .saveicon{
    color: #734BD1;
    cursor: pointer;  
    font-size: 17px;
    align-items: center;
    margin-top: 7px;
  }
  
  .editsave{
    position: relative;
    /* left: -15px; */
    left: -23px;
    top: 20px;
    border: 1px solid #80808017;
    /* border: 1px solid green; */
    border-radius: 50px;
    height: 30px;
    width: 20px;
    align-items: center;
    justify-content: center;
    padding-left: 7px;
    padding-right: 3px;
    background-color: #FAF8FF;
  }
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){

.mobile-profile-sidebar-container {
    display: grid;
    position: absolute;
    background-color: #ffffff;
    /* right: 0; */
    left: 0;
    top: 0%;
    /* height: calc(100vh / 1); */
    height: 100% !important;
    z-index: 999;
    width: 100% !important;
    /* padding: 1rem; */
    box-shadow: 0px 4px 15px #00000029;
    transition: all .25s ease-in-out;
    /* animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards; */
}
.profile-detials-left{
  /* border: 1px solid black; */
   /* height: 2.5rem; */
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   font-size: .8rem;
}

/* {
    color: white;
} */
.profile-header-left{
    padding: 0 1rem;
    background-color: var(--primary);
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: 50%;
    height: 50%;
    /* position: fixed; */
    /* width: 100%; */
    z-index: 5;
}
.profile-header-right{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 2rem;
    
  }
  .mobile-sidebar-avatar{
    margin-left: 1rem !important;
  }
  .profile-header-right > h1{
    font-size: 1.2rem !important;
    font-weight: bold !important;
  }
  
  .profile-header-left span{
    cursor: pointer;
    font-size: 1.2rem;
    width: 2rem;
    text-align: center;
    font-weight: bolder;
    color: white;
    /* border-radius: 50%; */
    /* background: #FAF8FF; */
    height: 100%;
    outline: none;
    border: none;
  }
  .mobile-profile-footer{
    height: unset !important;
  }
  





  .singlerow{
    align-items: center;
    width: 100%;
    /* justify-content: center;
    display: flex; */
    /* border: 1px solid blue; */
    /* margin-left: 10%;
    margin-right: 10%; */
    width: 145px;
  }
  .settrow{
    display: flex;
  }
  .dropdowns{
    /* border:1px solid green !important; */
    /* box-shadow: 1px 1px 10px #888888,1px 1px 10px #888888,1px 1px 10px #888888; */
    border-radius: 0px  !important;
  
    /* min-width: 10px;  */
    max-width: 120px;
    padding: 2px ;
  }
  .dropdowns2{
    /* border:1px solid green; */
    border-radius: 0px 15px 15px 0px  !important;
    /* box-shadow: 1px 1px 10px #888888; */
  
    /* min-width: 10px;  */
    max-width: 50px;
    padding: 2px ;
  }
  .dropdowns3{
    /* border:1px solid green; */
    border-radius: 0px 15px 15px 0px  !important;
    /* box-shadow: 1px 1px 10px #888888; */
  
    /* min-width: 10px;  */
    width: 75px;
    padding: 2px ;
  }
  .dropdownsicon{
    /* border:1px solid green; */
    border-radius:  15px 0px 0px 15px;
    width: 20px;
    height: fit-content;
    padding-left: 2px;
  }
  .dropdownCalltype > i{
    color: #734BD1;
  }
  icon svg, .dropdownsicon svg{
    fill: var(--primary)  !important;
  }
  .unselect_icon svg{
    color: #d4d4eb;
    /* background-color: #FAF8FF; */
    height: 1.2rem;
  }
  .notification_icon_box{
    margin-left: 5px;
    display: flex;
    /* justify-content: space-around; */
  }
  .unselect_icon, .select_icon{
  
    /* border: solid 1px var(--primary-text-color); */
   /* border-radius: 100vh; */
  }
  .select_icon svg{
    /* background-color: #FAF8FF; */
    fill: var(--primary-text-color);
    height: 1.2rem;
  }
  align-notification-iocn{
    margin-top: 5px;
      margin-bottom: 9px;
      /* display: flex; */
      /* flex-direction: column; */
      /* justify-content: space-around; */
      /* align-items: center; */
      font-size: 15px;
      /* margin-left: auto;
      margin-right: auto; */
  }
  .notification_icon_box{
    max-width: 120px;
    width: 20rem;
  }
  .editicon{
    color: #734BD1;
    cursor: pointer;  
    font-size: 17px;
    align-items: center;
  }
  .saveicon{
    color: #734BD1;
    cursor: pointer;  
    font-size: 17px;
    align-items: center;
    margin-top: 7px;
  }
  
  .editsave{
    position: relative;
    /* left: -15px; */
    left: -23px;
    top: 20px;
    border: 1px solid #80808017;
    /* border: 1px solid green; */
    border-radius: 50px;
    height: 30px;
    width: 20px;
    align-items: center;
    justify-content: center;
    padding-left: 7px;
    padding-right: 3px;
    background-color: #FAF8FF;
  }
}