.row--three>.content__header__searchbox > input:focus {
    /* border:0;
    outline:none;
    box-shadow:none; */
    border: none !important;
   }

.contacts-container{
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    flex-grow: inherit;
    background: var(--secondary-color) !important;;
}

.contacts-header{
    display: flex;
    /* height: 2%; */
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem 1rem 1rem 2rem;
    border: 2px solid #dcd8d840;
}

/* .contacts-body{
    height: 100%;
    overflow-y: scroll;
} */


ul{
    margin: 0;
    width: 100%;
   
    cursor: pointer;
    list-style:none;
}

.contacts-body > ul > li{
    text-decoration: none;
    padding: 1.5rem;
    padding-top: 0.5rem;
}

.contacttListItem-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    /* width: 100%; */
    background: #ffffff;
    /* height: 100%; */
    box-shadow: 0px 2px 8px #00000024;
    border-radius: 4px;
    height: 4.5rem;
    margin: 1rem;
    padding: 0 1rem;
}

.avatar-div{
    display: flex;
    padding: 1rem;
    align-items: center;
   
}

.list-title{
    display: flex;
    justify-content: space-between;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-size: medium;
    font-weight: bold;
    text-align: start !important;
    color: var(--primary-color);
    align-items: center;
    padding-top: 1rem;
    position: sticky;
    top: 0;
    background: var(--secondary-color) !important;
    z-index: 1;
}
.contact-scroll-list li .contacttListItem-div:hover, .grid-item-div:hover{
    background-color: var(--grey-100) !important;
}



/* .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
} */

.row--three>.content__header__searchbox>.search {
    border: none;
}
.contacts-container .row--three>.content__header__searchbox {
    /* padding: 10px 1rem 10px 0rem; */
    background-color: #fff!important;
    border-radius: 100vw;
    display: flex;
    flex: 0 0 33.33333%;
    margin: 0;
}
.contact-search-btn:focus{
    border: none !important;
}
.row--three>.content__header__searchbox > input {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none;
}

.row--three #basic-addon1, .row--three #basic-addon3 {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

}
@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine){
    .contacts-header >.content__header__searchbox {
        display: flex;
        width: 50%;
        margin: 0 auto;
    
    }
    .row--three #basic-addon1, .row--three #basic-addon3 {
        background-color: #fff!important;
        border: none;
    }
    .contactListView{
        /* display: block;
        position: absolute;
        
        right: 1rem; */
        /* top: 3rem; */
        display: flex !important;
        position: relative !important;
        align-items: center;
        flex: 0 0 33.33333%;
    }

    .contactListViewButtonsContainer {
        justify-content: flex-end;
    }
}


input:focus {
    outline: none !important;
    border:none;
  }

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.contactType, .contactTypeHeader{
    width: 20%;
}
.contactPhone, .contactPhoneHeader{
    width: 30%;
    display: flex;
    justify-content: flex-end;
}
.contactNameHeader, .contactName{
    width: 50%;
}

.create_contact{
    
        background: var(--primary-color);
        padding: 0.5rem 0.7rem;
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    
}

.spinnerCircle{
    width: 100%;
    display: flex;
    justify-content: center;
}
.floating-button{
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    border-radius: 100vw;
    width: 1.3rem;
    height: 1.5rem;
    cursor: pointer;
    box-shadow: 0px 2px 8px #00000052;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    margin-left: 1rem;
}


/* Grid Layout */

.main-grid-container{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-rows: repeat(20px,1fr);
    grid-template-rows: repeat(4, 9rem);
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    padding: 1rem;
}

.grid-item-div{
    background: #ffffff;
    box-shadow: 0px 2px 8px #00000024;
    border: none;
    border-radius: 5px;
    height: 9rem;
}

.contact-item-div{
    height: 100%;
    display: grid;
    /* justify-content: center; */
    /* align-items: flex-start; */
    grid-template-columns: 35% 65%;
    grid-template-rows: 1fr;
}

.grid-avatar-div{
    padding: 1rem 2rem 2rem 2rem;
}

.grid-usercontact-div{
    display: flex;
    flex-direction: column;
    padding-top: 0.7rem;
    align-items: flex-start;
    margin: 0;
}


.contactListView i {
    color: var(--primary-color);
    margin: 0 .5rem;
    cursor: pointer;
}
.contactListView .fa-plus {
    color: #ffffff;
    margin: 0 .5rem;
    cursor: pointer;
}

.contacttListItem-divMobile{
    display:none;
}
.contactsSearchDiv{
    width: 100%;
}

/* for Mobile */
@media only screen and (max-width: 1200px) { 
       
    .row--three #basic-addon1, .row--three #basic-addon3 {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        
            background-color: #f4f0ff!important;
          
            border: none;
    }
    
    .contactsSearchDiv .generic-search-input{
        background-color: #f4f0ff!important;
        border: none;
    }
    .contacts-container .row--three>.content__header__searchbox {
       display: flex;
       flex: 0 0 100%;
       width: 100% !important;
    }
    .contacts-container .row--three {
        background: white;
     }
     .row--three>.content__header__searchbox > input {
        background-color: #f4f0ff!important;
        border: none;
    }
    .contacttListItem-div {
       padding: 0 !important;
        margin: 0;
       
    }
    .contacts-header{
        padding: 1rem 2rem 1rem 2rem;
    }
    .eachContactsIcons i{
        margin: 0 0.5rem;
    color: var(--primary-color);
    
    }
    
    .contactPhone{
        width: 100%;
        display: block;
    }
    .contacttListItem-divMobile{
        display:flex;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 1px solid #00000024;
    }
    

    .contacts-body .floating{
        position: fixed;
        bottom: 3rem;
        right: 1rem
    }

    .contactNameHeader, .contactName {
        width: 100%;
    }
    .eachContactsIcons{
        display: flex !important;
    }
    .contacts-container {
       
        background: white !important;
    }
    .contacts-header{
        padding: 0.4rem !important;
    }

  }
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) { 
       
    .row--three #basic-addon1, .row--three #basic-addon3 {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        
            background-color: #f4f0ff!important;
          
            border: none;
    }
    
    .contactsSearchDiv .generic-search-input{
        background-color: #f4f0ff!important;
        border: none;
    }
    .contacts-container .row--three>.content__header__searchbox {
       display: flex;
       flex: 0 0 100%;
       width: 100% !important;
    }
    .contacts-container .row--three {
        background: white;
     }
     .row--three>.content__header__searchbox > input {
        background-color: #f4f0ff!important;
        border: none;
    }
    .contacttListItem-div {
       padding: 0 !important;
        margin: 0;
       
    }
    .contacts-header{
        padding: 1rem 2rem 1rem 2rem;
    }
    .eachContactsIcons i{
        margin: 0 0.5rem;
    color: var(--primary-color);
    
    }
    
    .contactPhone{
        width: 100%;
        display: block;
    }
    .contacttListItem-divMobile{
        display:flex;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 1px solid #00000024;
    }
    

    .contacts-body .floating{
        position: fixed;
        bottom: 3rem;
        right: 1rem
    }

    .contactNameHeader, .contactName {
        width: 100%;
    }
    .eachContactsIcons{
        display: flex !important;
    }
    .contacts-container {
       
        background: white !important;
    }
    .contacts-header{
        padding: 0.4rem !important;
    }

  }


@media only screen and (max-width: 768px) {
    .contacts-header{
        padding: 1rem 1rem 1rem 1rem;
    }
  }



