.sidebar-container{
    display: grid;
    grid-template-rows: 10% 90%;
    background: var(--secondary-color) !important;
}

.zing-logo-div{
    grid-row: 1;
    grid-column: 1;
    background: #fff !important;
    gap: 0;
    box-shadow: 0px 4px 15px #00000029;
}

.logo-img{
    /* height: 55%; */
    padding: 0.9rem 2rem !important;
}

.logo-img img{
    border-style: none;
    width: 100%;
}

.menu-btn-div{
    padding: 1.2rem 0rem;
    height: calc(100vh - 9rem);
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    scrollbar-width: thin;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
}

ul{
    margin: 0;
    width: 100%;
    padding: 0;
    cursor: pointer;
}

.menu-btn-div > ul > a >h3{
    font-size: small;
    font-weight: bold;
    background: none !important;
}



.menu-btn-div > ul > a{
    
    text-align: center;
    display: block;
    border-radius: 1rem;
    padding: 1.2rem 0.4rem;
    /* width: 3.6rem; */
    color: var(--primary-color);
    height: max-content;
    margin: 0.8rem;
    text-decoration: none;
    cursor: inherit;

}

.menu-btn-div > ul > a:not(:last-child){
margin-bottom: 1rem;
}

.sidebar-container >.menu-btn-div > ul > a:hover{
    background: #e1d5ff !important;
    box-shadow: 0px 4px 15px #00000029;
    color: var(--secondary-text-color);
} 


.active {
    color: var(--tertiary-color) !important;
    background: #fff !important;
}



/* for mobile */


