.accordion-button{
    width:97% !important;
    color: var(--dark-grey) !important;
    background-color:var(--white-color) !important;
    font-weight: bold;
}
.accordion-button:not(.collapsed) {
    width:97% !important;
    color: var(--white-color) !important;
    background-color:var(--primary-color) !important;
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
  .accordion-button::after{
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%734bd1'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
  /* .accordion-button::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  } */
.formName{
    margin-top: 10px !important;
    width: 100%!important;
}
.formtype{
    /* margin-top: 20px !important; */
    width: 100%!important;
}
.blobpath{
    margin-top: 10px !important;
    width: 100%!important;
}
.baseurl{
    margin-top: 10px !important;
    width: 100%!important;
}
.form-input{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.form-radio-select{
    /* margin-top: 15px; */
}
#formdemo-customized-radios{
    font-size: 500 !important;
    color:var(--primary-color) ;
    font-weight: bold;
    font-size: small;
    margin: 0.5rem;
}
.accordion-body{
    height: 14rem;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 2rem 1.25rem 0 1.25rem;
}
/* .Fieldsaveitem{
    border-radius: 1rem;
    padding: 0.3rem 4rem;
    background-color: var(--primary-color);
    border: none;
    color: white;
    font-size: medium;
} */
.table-btn{
   margin-left: 43em;
}
.accordion-bodyDelivery
{
    height: 14rem;
    overflow-y: scroll;
    display: grid;
    overflow-x: hidden;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 2rem 1.25rem 1rem 1.25rem;
}
.saleforce{
    display: flex !important;
}
.tabledata thead{
    color:var(--primary-color) ;
}
.tabledata thead tr th{
    font-size: small;
    font-weight: bold;
    color:var(--primary-color);
}
#fieldtype-names{
    width: 6rem;
    border-radius: 100vw;
    font-size: 1rem;
    padding: 0;
    color: var(--secondary-color);
}
#deltype-names{
    width: 6rem;
    height:1.3rem;
    border: none;
    border: 1px solid grey;
    border-radius: 10px;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: var(--secondary-color);
    margin-bottom: 2rem;
}
.fielddata{
    font-size: medium;
}
.additem{
    border-radius: 1rem;
    padding: 0.3rem 1rem;
    background-color:var(--white-color);
    border: none;
    color: var(--primary-color);
    font-size: large;
    margin-right: 10px;
    font-weight: 700;
    display: flex;
}
.add{
    /* font-size: 15px; */
    font-weight: bold;
}
.date{
    border: none;
    margin-left: 1rem;
}
/* .fa-plus{
    color:var(--primary-color);
    background-color: transparent;
} */
.formsign{
    margin-left: 10%;
    margin-right: 10%;
}
.save{
  display: flex;
  justify-content: center;
  margin: 1rem;
}
.wavecontent{
    padding: 0;
    font-size: medium;
    margin-bottom: 0 !important;
    color: var(--primary-color);
}
.saveitem{
    border-radius: 2rem;
    padding: 0.5rem 4rem;
    background-color: var(--primary-color);
    border: none;
    color: white;
    font-size: medium;
}
.delivery{
    display: flex;
    padding: 0 7rem;
}
.fieldcontent{
    padding: 2rem;
    font-size: medium;
    margin-bottom: 0 !important;
    color: var(--primary-color);
}
.drpdowncontent{
    font-size: medium;
    margin-bottom: 0 !important;
    color: var(--primary-color);
}
.drpdownactioncontent{
    font-size: medium;
    margin-bottom: 0 !important;
    color: var(--primary-color);
    /* margin-top: 3rem; */
}
.deliveryContent{
    /* display: flex;
    justify-content: space-between; */
    margin: 1rem;
}
.formcontrol{
    /* display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox; */
    display: inline-flex;
    /* -webkit-align-items: center; */
    -webkit-box-align: center;
    /* -ms-flex-align: center; */
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-right: 12px;
}

.delivery-values{
    margin-left: 1rem;
}
.FormDate{
    margin-top: 1rem;
}
.WaveFormEndDate{
    margin-top: 15px;
}
.wave-accordion-body{
    height: 14rem;
    overflow-y: scroll;
    display: grid;
    grid-gap: 10px 20px;
    padding: 2rem 1.25rem 0 1.25rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0fr;
}
.genericDropdown_label {
    /* color: #734BD1 !important; */
    /* background: white; */
    /* z-index: 1;
    display: block !important;
    transform: translate(14px, -9px) scale(0.75); */
    /* position: relative !important; */
    /* font-weight: bold !important; */
}
.genericDropdown_select {
    /* width: 100%;
    border: 1px solid #d3d3d370 !important;
    border-radius: 100vw !important; */
    /* bottom: 23px; */
}
/* .genericDropdown_select.generic_form_select{
    bottom: 23px;
} */
.dropdown-menu-item, .multi-select-itemlist{
    display: flex !important;
    justify-content: flex-start !important;
    padding:0.2rem 0 0.2rem 0.3rem !important;
}

.multi-select-itemlist >span {
    padding: 9px !important;
}
.formcontrol{
    margin-left: 0 !important;
    margin-right: 17% !important;
}
.radio-formcontrol{
    margin-left: 10% !important;
    margin-right: 10% !important;
}
.radio-formcontrol-salesforce {
    margin-left: 4% !important;
    margin-right: 7% !important;
}