.slider{
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    width: 100vw;
    
    border-right: 1px solid #eff1f2;
    touch-action: none;
    
    /*opacity: 0;
    visibility: hidden;
    
    overflow: hidden;
     */
}

.slider-block__header {
    padding: 1.5rem 1.5rem 1rem 0rem;
    /* background-image: url(/images/Profile\ BG.png); */
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: 50%;
    height: unset;
    /* position: fixed; */
    width: 100%;
    z-index: 5;
}


.slider .bar1,.slider  .bar2,.slider  .bar3 {
    
    background-color: #ffffff;
    
} 

.slider .content__header__bars{
    transform: scaleX(-1);
    margin-left: 1rem;
}

.slider .user-name{
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    color: #ffffff;
}

.user-name h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    font-weight: 100;
    font-size: 1.3rem;
}

.slider-block .mob-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    background: #fff;
    position: fixed;
    bottom: 0;
}
.slider-block .mob-footer img {
    height: 2rem;
}

.slider .avatar-div{
    width: 6rem;
    height: 6rem;
}


.slider-block .mid {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    min-height: 15rem;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #faf8ff;
    padding: 1rem 0.8rem;
}






/* new */
.mobileSideBar{
    position: fixed;
    width: 100%;
    height: 100% !important;
    background: #faf8ff;
    z-index: 3;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.mobileSideBar_upper{
    /* height: 100%; */
}

.mobileSideBar_footer img{
    height: 1rem;
}
.mobileSideBar_footer{
    z-index: 1000;
    position: relative;
}
.mobileSideBar_footer footer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    background: white;
}
.mobileSideBar_footer.home-footer{
    position: fixed;
}
.mobileSideBar_header{
    padding: 1rem 1rem 1rem 1rem;
    /* background-image: url(/images/Profile\ BG.png); */
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: 50%;
    height: unset;
    /* position: fixed; */
    /* width: 100%; */
    z-index: 5;
}

.mobileSideBar_header .bar1,.mobileSideBar_header  .bar2,.mobileSideBar_header  .bar3 {
    
    background-color: #ffffff;
    
} 

.mobileSideBar_header .content__header__bars{
    transform: scale(-1);
    display: grid;
    justify-content: end;
}


.mobileSideBar_header .row--five{
    padding-top: 1rem;
}
.mobileSideBar_header .user-name{
    margin-left: 1rem;
    color: white;
}

.mobileSideBar_main{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
}

.mobileSideBarIcons{
    color: var(--primary-color);
    width: 3rem;

}

.mobileSideBar_main_links b,.mobileSideBar_main_logout b{
    font-weight: 600 !important;
}

.mobileSideBar_main_links li{
    padding: 0.3rem 0rem;
}


.mobileSideBar .avatar-div{
    height: 4rem;
    width: 4rem;
    padding: 0rem;
}

.mobileSideBar_main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height:  calc(100% - (8rem + 6rem)); */
}

.mobileSideBar_main_logout img{
    width: 2rem;
    margin-right: 1rem;
    
}
.mobileSideBar_main_logout b{
padding-top:0.5rem ;
}

.mobileSideBar_main_links a {
    text-decoration: none;
    display: flex;
    color: var(--secondary-text-color);
    display: flex;
    align-items: center;
}
.mobileSideBar_main_links a h4,.mobileSideBar_main_logout h4{
   
    color: var(--secondary-text-color);
   
}
.homeIcon{
    padding-right: 1.5rem;
}

.app-version {
    font-size: 1rem;
}