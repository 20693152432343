.form-fields-div {
    display: flex;
    flex-direction: column;
    align-items: normal;
    row-gap: 1rem;
}


.disableNumber .MuiInputBase-formControl{
    background-color: #f7f4ff!important;
}

.SaveButtonForchat{
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 1200px){
    .ipad-top-margin{ 
        margin-top:var(--marginTop);
        height:100%!important;
        z-index: 1000;
    }
    /* .edit-contact-box.ipad-top-margin{
        height: 100%;
    } */

}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse){
    .ipad-top-margin{ 
        margin-top:var(--marginTop);
        height:100%!important;
    }


}