.login-bg{
    background: transparent radial-gradient(closest-side at 50% 50%, #fdfcff 0%, #fbf9ff 31%, #f9f6ff 61%, #ede6ff 100%) 0% 0% no-repeat padding-box;
  }
  
  /* desktop */
  .login-layout-bg{
      display: flex;
      touch-action: none;
      overflow-y: hidden;
      flex-direction: row;
      height: 100vh;
      align-items: center;
  }
  
  .login-left-div{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: 50vw;
      padding: 10px;
  }
  
  .login-left-div img{
      height: 60%;
  }
  
  .login-right-div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50vh;
      width: 50vw;
      padding-right: 20px !important;
      padding-left: 20px !important;
  }
  
  .login-text{
      text-align: center;
  }
  
  .font--xl{
      font-size: 3rem;
     margin-bottom: 2rem;
      color: var(--secondary-text-color);
  }
  .font--lg{
      font-size: 1.8rem;
      margin-bottom: 2rem;
      font-weight: 400;
      color: var(--secondary-text-color);
  }
  .font--btn{
      font-size: 1.4rem;
      margin-bottom: 2rem;
      color: var(--secondary-text-color);
  }
  
  .rounded-primary-40 {
      border-radius: 4rem;
      background-color: var(--primary-color);
      color: #fff;
      opacity: 1 !important;
      font-weight: 600;
      margin-bottom: 2rem;
      padding: 1rem 1.5rem;
      box-shadow: 0px 0px 15px #00000026;
      white-space: nowrap;
      border-radius: 4rem;
      cursor: pointer;
  }
  
  .btn{
      border: 1px solid transparent;
      display: inline-block;
      outline: none;
      position: relative;
      transition: all ease-in-out 0.25s;
  }
  
  /* Mobile */
  @media (max-width: 1200px) {
  
      .login-layout-bg{
          flex-direction: column;
          overflow-y: auto;
      }
  
      .login-left-div{
          height: 50vh;
      }
      .login-left-div img{
        height: 90%;
      }
  
      .login-right-div{
          justify-content: flex-start;
          height: 30vh;
          width: 90%;
      }
      
      .font--xl{
          font-size: 1.7rem;
         margin-bottom: 1rem;
          color: var(--secondary-text-color);
      }
      .font--lg{
          font-size: 1rem;
          margin-bottom: 1rem;
          font-weight: 400;
          color: var(--secondary-text-color);
      }
      .font--btn{
        font-size: 1rem;
        margin-bottom: 2rem;
        color: #ffffff;
    }

    .rounded-primary-40 {
        padding: .5rem .8rem;
    }
  
  }
  @media (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {
  
      .login-layout-bg{
          flex-direction: column;
          overflow-y: auto;
      }
  
      .login-left-div{
          height: 50vh;
      }
      .login-left-div img{
        height: 90%;
      }
  
      .login-right-div{
          justify-content: flex-start;
          height: 30vh;
          width: 90%;
      }
      
      .font--xl{
          font-size: 1.7rem;
         margin-bottom: 1rem;
          color: var(--secondary-text-color);
      }
      .font--lg{
          font-size: 1rem;
          margin-bottom: 1rem;
          font-weight: 400;
          color: var(--secondary-text-color);
      }
      .font--btn{
        font-size: 1rem;
        margin-bottom: 2rem;
        color: #ffffff;
    }

    .rounded-primary-40 {
        padding: .5rem .8rem;
    }
  
  }