*{
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: "SF Pro", sans-serif;
}
:root {
  --primary: var(--primary-color);
  --white: #ffffff; 
  --dark--grey--500: #595959;
  --bg--purple: #faf8ff;
  --light--blue--magenta: #f7f4ff;
  --dark--grey: #595959;
  --grey-100: #eee6ff;
  --marginTop: env(safe-area-inset-top);
  /* --marginTop: 20px; */
  /* --grey-dark--200:#828287; */
}
/* html { font-size: 16px; } */

.App {
  text-align: center;
}
ul{
  list-style: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}
.modal-box{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28%;
    border-radius: 1rem;
    background-color: white;
    border: none;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  scrollbar-width: thin;
}

.App-link {
  color: rgb(112, 76, 182);
}
.form-error-message{
  font-size: small;
  color: red;
}
input{
  color: var(--dark--grey--500) !important ;
}
@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}




@media only screen and (min-width: 1200px) and (hover: hover) and (any-pointer: fine)
{
  .font-lg {
    font-size: 2.2rem!important;
    line-height: 2.8rem;
}
.bg-gray {
  background: #f5f5f5!important;
}
}



.disabledInput .css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root,.disabledInput .MuiInputBase-colorSecondary{
  background: rgb(243, 241, 245) !important;
}


.w-40{
  width: 40%;
}
.w-30{
  width: 30%;
}

.fullwidth{
  width: 100%;
}
.primary{
  color: var(--primary-color)!important;
}

.MuiInputBase-formControl{
  border-radius: 100vw !important; 
  border: 1px solid #d3d3d370 !important;
}
.mr-2{
  margin-right: 0.5rem;
}

/* input:focus{
  border-radius: 100vw !important; 
  border: 1px solid #d3d3d370 !important;
} */







@media only screen and (max-width: 1200px)

{
 .notMobile{
  display: none !important;
 }
 .onlyMobile{
  display: block;
 }
 /* .MuiModal-root .MuiBox-root{
  width: 100% !important;
    height: 100vh !important; 
 } */
 .modal-header{
  background-color: var(--primary-color) !important;
 }
 .modal-header .close{
  width: 1.5rem;
    color: #fff;
    
    background-color: transparent !important;
    border-radius: 0 0 0 90px;
    
 }
 .modal-box{
  width: 99%;
  height: 99%;
  border-radius: none;
}
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse)

{
 .notMobile{
  display: none !important;
 }
 .onlyMobile{
  display: block;
 }
 /* .MuiModal-root .MuiBox-root{
  width: 100% !important;
    height: 100vh !important; 
 } */
 .modal-header{
  background-color: #b29ce6 !important;
 }

 .modal-box{
  width: 99%;
  height: 99%;
  border-radius: none;
}
}

@media only screen and (min-width: 1200px)  and (hover: hover) and (any-pointer: fine)
{
 .oMobile{
  display: block;
 }
 .onlyMobile{
  display: none;
 }
 /* .css-1ujx0qs{
  width: unset;
    height: unset; 
 } */


}



.content__header__searchbox .form-control{
padding-left: 0;
    color: var(--primary-text-color);
    font-weight: 500;
}
.content__header__searchbox .form-control:focus{
  border: none;
  }

.content__header__searchbox i{
  color: var(--primary-text-color);
  font-size: 0.8rem;
}


.MuiOutlinedInput-notchedOutline{
  /* border-width: 0.8px !important; */
  min-width: 0%;
  border: none !important;
  
}