
.phoneNumberInput > label{
    display: block;
    position: absolute;
    /* top: 1rem; */
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    background: white;
    overflow: hidden;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    transform: translate(77px, -9px) scale(0.75);
    color: var(--primary-color) !important;
    font-weight: bold !important;
}


.phoneNumberInput input{
    font: inherit;
    color: #000000ba;
    font-size: 1rem;
    letter-spacing: inherit;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.3rem;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
    border: 1px solid #d3d3d370;
    border-radius: 100vw;
    background: white;
}

.phoneNumberInput input:focus{
   
    border: 1px solid #d3d3d370;

}