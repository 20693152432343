.avatar-container{
    border-radius: 50%;
    /* margin-right: 1rem; */
    /* width: 4rem;
    height: 3.5rem; */
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    color: var(--primary);
    overflow: hidden;
    background-color: #faf8ff;
    border: 1px solid rgba(115,75,209,.301961);  
}
.avatar-container svg, .avatar-container img{
    width: 100%;
    fill: var(--primary)
}
.other-staff svg, .other-staff img{
    width: 100%;
    height: 80%;
}