.videoFloaterDiv{
    position: fixed;
    max-height: 50%;
    max-width: 12rem;
    z-index: 2;
    right: 0;
    overflow-y: auto;
    top: 20%;
    border-radius: 5px;
    padding: 0.5rem;
    box-shadow: -4px 1px 10px 0px #80808061;
    text-align: center;
    font-size: 1rem;
}

.videoFloater_header{
    display: flex;
    justify-content: space-between;
}

.videoFloater_video{
    background: white;
    height: 5.5rem;
    margin-bottom: 0.3rem;
    color: black;
    width: 100%;

}


/* chatwindow */
.videoFloater_chatDiv{
    position: fixed;
    width: 20rem;
    height: 16rem;
    font-size: 0.95rem;
    font-weight: 100;
    background: aliceblue;
    bottom: 0;
    right: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: -4px 1px 10px 0px #80808061;
    
}
.videoFloater_chatMain{
    overflow-y: auto;
    padding: 1rem;
}
.videoFloater_chatFooter{
    background: rgb(66, 45, 115);
    height: 2.3rem;
    display: flex;
    padding: 0.6rem;
}
.videoFloater_chatFooter input{
  width: 100%;
  margin-right: 0.4rem;
  border-radius: 0.25rem;
  border: none;
  padding: 0.2rem;
}

/* message */
.videoFloater_outgoing{
    display: flex;
    justify-content: flex-end;
    /* background-color: white; */
    padding: 0.25rem;
    margin: 0.25rem;
}
.videoFloater_incoming{
    display: flex;
    justify-content: flex-start;
    /* background-color: white; */
    padding: 0.25rem;
    margin: 0.25rem;

}

.videoFloater_videoDiv{
    width: 12rem;
    height: 9rem;
    overflow-y:auto ;
}

/* .videoFloater_videoDiv > div{
    position: relative;
    vertical-align: middle;
    align-self: center;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    box-shadow: var(--shadow-dark);
    background: #fff;
    animation: show 0.4s ease;
  
   
}

.videoFloater_videoDiv >div video{
    position: absolute;
    right: 0;
    object-fit: cover;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 10px;
    overflow: hidden;
    left: 0;
    top: 0;
    background-size: cover;
    overflow: hidden;
    -webkit-transition: margin-top 1s ease-in-out;
    -moz-transition: margin-top 1s ease-in-out;
    -o-transition: margin-top 1s ease-in-out;
    transition: margin-top 1s ease-in-out;
} */




.mobileCallFloater{
    position: absolute;
    width: 100%;
    padding-bottom: 1rem;
    background-color: blueviolet;
    z-index: 100;
    bottom: 0;
}

.mobileCallFloaterActions{
    display: flex;
    justify-content: space-evenly;
}

.mobileCallChat{
    height: 25rem;
    background-color: white;
    border: 1px solid lightgray;
    padding: 0.5rem;
    box-shadow: 0px -4px 12px 1px #888888ad;
   
}

.sectionChatMain input{
    width: 100%;
}


.videoFloater_chatMain li{
    list-style: none;
    border: 0.5px solid gray;
    /* width: 100%; */
}
/* 
..videoFloater_chatMain li{
    list-style: none;
} */


.chatListDivVideo{
    width: 93%;
}