.genericButton{
    border: 1px solid transparent;
    border-radius: 4rem;
    text-decoration: none;
    background-color: var(--primary-color);
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    position: relative;
    transition: all ease-in-out 0.25s;
    color: #fff;
    font-weight: 600;
    margin-bottom: 2rem;
    padding: 0.5rem 1.2rem;
    box-shadow: 0px 0px 15px #00000026;
    white-space: nowrap;
    margin: 0 auto;
}

