 .chatwindow{
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #fff;
    background: #f7f7f7;
}

/* for Mobile */
@media only screen and (max-width: 1200px) {

    .chatwindow{
        display: none;
    }
    .page-name-div h3 {
        font-size: 1.8rem!important;
        font-weight: 700;
        line-height: 3.3rem;
        color: var(--secondary-text-color) !important;
        margin-left: 0.5rem;
        margin-bottom: 1rem;
    }
  }
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse) {


    .page-name-div h3 {
        font-size: 2rem!important;
        font-weight: 700;
        line-height: 3.3rem;
        color: var(--secondary-text-color) !important;
        margin-left: 0.5rem;
        margin-bottom: 1rem;
    }
  }

