:root{
  --visibility: hidden;
}


.header-div{
    grid-template-columns: 10% 90%;
    grid-row: 1;
    grid-column: 1;
    position: relative;
    background: var(--primary-color);
    justify-content: space-between;
    top: unset !important;
    display: grid;
}

.autopilot-switch{
  margin: 9px 0;
}

.page-name-div{
    display: flex;
    align-items: center;
    margin-left: 1rem;
}
/* #headerVal{
  height: 100%;
} */


.page-name-div h3{
    white-space: nowrap;
    color: #ffffff;
    font-weight: 200;
    margin-bottom: 0 !important;
}

.page-name-div h3::after {
  content: attr(data-content);
}

.profile-btn-div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1rem;
}
.profile-btn-div h3{
    color: #ffffff;
}

.profile-inner-div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 17rem;
}

#desktoplogoutBtn{
    color: #ffffff;
    font-size: 1.8rem;
}

.autopilot-label{
    font-size: 1.2rem;
    color: #ffffff;
    visibility: var(--visibility);
}
/* Switch Button */

.autopilot-switch {
  
  display: inline-block;
  
 
  /* margin-bottom: 0rem !important; */
 
    position: absolute;
   
    right: 7rem; 
    width: 4rem;
    height: 2rem;

    visibility: var(--visibility);
  
}


.autopilot-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.b-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
}

.b-slider:before {
    position: absolute;
    content: "";
    left: 0.2rem;
    bottom: 0.09rem;
    background-color: white;
    height: 1.8rem;
    width: 1.8rem;
  
}

input:focus + .b-slider {
  box-shadow: 0 0 1px #ffffff;
}

input:not(:checked) + .b-slider:before {
  font-family: "Material Icons";
  content: "\e0b7";
  color: #3d3d3d;
  padding: 0.3rem 0.5rem;
 
    font-size: 2.3rem;
  
}

input:checked + .b-slider:before {
  
  
  font-family: "Material Icons";
  content: "\ea9c";
  color: #3d3d3d;
  padding: 0.2rem 0.6rem;
  
    font-size: 2.3rem;
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
  
}
.autopilot-switch {
 
    right: 0rem;
    position: relative;
  
  
}.b-slider {
    content: "" !important;
  }
  .b-slider:before {
   
      background-color: var(--primary-color);
    
  }

  input:not(:checked) + .b-slider:before {
    content: "OFF";
    font-family: "SF Pro", sans-serif;
   
    text-align: center;
  
      color: #fff;
      padding: 0.45rem 0rem;
      font-size: .65rem;
    
  }
  input:checked + .b-slider:before {
    content: "ON";
    font-family: "SF Pro", sans-serif;
    
   
   
    text-align: center;
   
      color: #ffffff;
      padding: 0.45rem 0rem;
      font-size: .65rem;
    
  }
  input:checked + .b-slider {
  
      background-color: #ffffff;
    
  }
  input:not(:checked) + .b-slider {
    
      background-color: #ffffff;
    }
  

/* Rounded sliders */
.b-slider.b-round {
  border-radius: 3.4rem;
}

.b-slider.b-round:before {
  border-radius: 50%;
}


/* Profile Header Sidebar UI */

.profile-sidebar-container{
  display: grid;
  position: absolute;
  background-color: #ffffff;
  right: 0;
  top: 100%;
  /* height: 87vh; */
  z-index: 999;
  width: 16rem;
  /* padding: 1rem; */
  box-shadow: 0px 4px 15px #00000029;

  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}


@keyframes slide-in {
  0% { -webkit-transform: translateX(70%); }
  100% { -webkit-transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    0% {transform: translateX(70%); }
   100% { transform: translateX(0%); }
}

@keyframes slide-out {
  0% { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(0%); }
}

@-webkit-keyframes slide-out {
    0% {transform: translateX(100%); }
   100% { transform: translateX(0%); }
}
.profile-header-right{
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 2rem;
}
.profile-header-right > h1{
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

.profile-header-right span{
  cursor: pointer;
  font-size: 1.2rem;
  width: 2rem;
  text-align: center;
  font-weight: bolder;
  border-radius: 50%;
  background: #FAF8FF;
  height: 100%;
  outline: none;
  border: none;
}
.profile-header-right > i{
  font-size: 1.2rem !important;
  width: 2rem;
}

.profile-avatar-right{
    display: flex;
    justify-content: center;
    height: 7rem;   
    align-items: center;
}

.profile-detials-right{
   /* border: 1px solid black; */
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: .8rem;
}

.profile-detials-email{
    font-size: .8rem;
    display: flex;
    justify-content: center;
}
.profile-detials-email label{
  padding: 0px;
}
.profile-detials-right .user-bold-font{
  font-weight: bold;
  font-size: .9rem;
  color: var(--primary-color);
}

.profile-qrcode-right{
  display: flex;
  justify-content: center;
  /* width: 5rem; */
  height: 11rem;
  align-items: center;
  
}
.profile-qrcode-right .qr-code-img{
  height: 100%;
}

.profile-btns-right{
 
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-btns-right button{
   margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 1.5rem;
    width: 2.5rem;
    border: none;
    border-radius: 100vw;
   background-color: var(--primary-color);
   display: flex;
    justify-content: center;
    align-items: center;
}

.icons-sizing{
  color: #ffffff;
  margin: 0;
  font-size: 13px;
  padding: 0;
}

.profile-footer-right{
  display: flex;
  justify-content: center;
  bottom: 0;
  height: 2rem;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
}

.poweredBy-img{
  height: 1rem;
  margin-top: 5px;
}


/* for mobile display none */
@media only screen and (max-width: 1200px)
{
  .header-div{
    display: flex !important;
    background:white;
  }
 
  .page-name-div h3 {
    font-size: 2rem!important;
    font-weight: 700;
    line-height: 3.3rem;
    color: var(--secondary-text-color) !important;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
}
.autopilot-label {
  font-size: 1.2rem;
  color: var(--primary-text-color);
  padding: 0rem 1rem;
  visibility: var(--visibility);
}
input:not(:checked) + .b-slider:before {
  content: "OFF";
  font-family: "SF Pro", sans-serif;
  text-align: center;
  color: var(--primary-text-color);
  padding: 0.45rem 0rem;
  font-size: .65rem;
  
}
input:checked + .b-slider:before {
  content: "ON";
  font-family: "SF Pro", sans-serif;
  text-align: center;
  color: var(--primary-text-color);
  padding: 0.45rem 0rem;
  font-size: .65rem;
}
}
@media only screen and (min-width: 1200px) and (hover: none) and (any-pointer: coarse)
{
  .header-div{
    display: flex !important;
    background:white;
  }
 
  .page-name-div h3 {
    font-size: 2rem!important;
    font-weight: 700;
    line-height: 3.3rem;
    color: var(--secondary-text-color) !important;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
}
.autopilot-label {
  font-size: 1.2rem;
  color: var(--primary-text-color);
  padding: 0rem 1rem;
  visibility: var(--visibility);
}
input:not(:checked) + .b-slider:before {
  content: "OFF";
  font-family: "SF Pro", sans-serif;
  text-align: center;
  color: var(--primary-text-color);
  padding: 0.45rem 0rem;
  font-size: .65rem;
  
}
input:checked + .b-slider:before {
  content: "ON";
  font-family: "SF Pro", sans-serif;
  text-align: center;
  color: var(--primary-text-color);
  padding: 0.45rem 0rem;
  font-size: .65rem;
}
}

.settings{
  margin-top: 5px;
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  /* border: 1px solid red; */

}




.settingsDisplay .icon{
  /* border: 1px solid blue; */
  color: var(--primary-color);
  margin-right: 5px;
}

.settingsDisplay .dataSpan{
  /* border: 1px solid red; */
  /* color: #734BD1;
  margin-right: 5px; */
  min-width: 70px;
  height: auto;
}

.settingsDisplay{
  margin-top: 5px;
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  /* border: 1px solid red; */

}

.singlerow{
  align-items: center;
  /* justify-content: center;
  display: flex; */
  /* border: 1px solid blue; */
  margin-left: 10%;
  margin-right: 10%;
  width: 145px;
}
.settrow{
  display: flex;
}
.dropdowns{
  /* border:1px solid green !important; */
  /* box-shadow: 1px 1px 10px #888888,1px 1px 10px #888888,1px 1px 10px #888888; */
  border-radius: 0px  !important;

  /* min-width: 10px;  */
  max-width: 120px;
  padding: 2px ;
}
.dropdowns2{
  /* border:1px solid green; */
  border-radius: 0px 15px 15px 0px  !important;
  /* box-shadow: 1px 1px 10px #888888; */

  /* min-width: 10px;  */
  max-width: 50px;
  padding: 2px ;
}
.dropdowns3{
  /* border:1px solid green; */
  border-radius: 0px 15px 15px 0px  !important;
  /* box-shadow: 1px 1px 10px #888888; */

  /* min-width: 10px;  */
  width: 75px;
  padding: 2px ;
}
.dropdownsicon{
  /* border:1px solid green; */
  border-radius:  15px 0px 0px 15px;
  width: 20px;
  height: fit-content;
  padding-left: 2px;
}
.calltypeicon{
  margin-right: 10px !important;
}
.icon svg, .dropdownsicon svg{
  fill: var(--primary)  !important;
}
.unselect_icon svg{
  color: #d4d4eb;
  /* background-color: #FAF8FF; */
  height: 1.2rem;
}
.notification_icon_box{
  margin-left: 5px;
  display: flex;
  /* justify-content: space-around; */
}
.unselect_icon, .select_icon{

  /* border: solid 1px var(--primary-text-color); */
 /* border-radius: 100vh; */
}
.select_icon svg{
  /* background-color: #FAF8FF; */
  fill: var(--primary-text-color);
  height: 1.2rem;
}
.dropdownCalltype > i{
  color: #734BD1;
}
.align-notification-iocn{
  text-align: center;
  margin-top: 5px;
    margin-bottom: 9px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-around; */
    /* align-items: center; */
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
}
.notification_icon_box{
  max-width: 120px;
  width: 20rem;
}
.editicon{
  color: var(--primary-color);
  cursor: pointer;  
  font-size: 17px;
  align-items: center;
}
.saveicon{
  color: var(--primary-color);
  cursor: pointer;  
  font-size: 17px;
  align-items: center;
  margin-top: 7px;
}

.editsave{
  position: relative;
  left: -15px;
  top: 20px;
  /* border: 1px solid green; */
  border-radius: 50px;
  height: 30px;
  width: 20px;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 3px;
  background-color: #FAF8FF;
}