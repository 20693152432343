.selectconvo__block {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.chatcontainer{
    
    height: 100%;
    width: 100%;
    
}
.shadowin{
    box-shadow: inset 0 0 10px #000000;
}
.d-flex {
    display: flex !important;
}
.avatar {
    border-radius: 50%;
    overflow: hidden;
    background-color: #FAF8FF;
    border: 1px solid #734BD14D;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
}
.welcome{
    color: var(--primary-text-color);
}

  .avt-4p5 {
      width: 4.5rem;
      height: 4.5rem;
  }

  .avt-4p5 .initials {
      font-size: 2.3rem;
  }

  .avt-5 {
      width: 5rem;
      height: 5rem;
  }

  .avt-5 .initials {
      font-size: 2.5rem;
  }

  .avt-6 {
      width: 6rem;
      height: 6rem;
  }

  .avt-6 .initials {
      font-size: 2.8rem;
  }

.avt-9 {
    width: 9rem;
    height: 9rem;
}
.avt-8 .initials {
    font-size: 2.2rem;
}
  .avt-12 {
      width: 12rem;
      height: 12rem;
  }

  .avt-12 .initials {
      font-size: 3.2rem;
  }

  .avt-16 {
      width: 16rem;
      height: 16rem;
  }

  .avt-16 .initials {
      font-size: 3.7rem;
  }

  .greeting-text {
    font-size:  1.5rem;
  }
  


